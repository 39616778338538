
import React, { useState, useEffect, useRef } from 'react';
import './navigation.scss';
import { useTranslate } from '../translate/translate';

import Header from './header'

import ProfileIcon from './profile.png';
import PortIcon from './port.png';
import VoyageIcon from './trip.png';
import MapIcon from './map.png';
import MarketIcon from './market.png';
import AirdropIcon from './airdrop.png';

import Profile from '../profile/profile';
import Port from '../port/port';
import Voyage from '../voyage/voyage';
import MapNav from '../map/map-nav';
import Market from '../market/market';
import Airdrop from '../airdrop/airdrop';

const Navigation = ({ setStep, setTutorial, serverTime }) => {
    const t = useTranslate();
    const [transitionState, setTransitionState] = useState('fade-in');
    const [activeSection, setActiveSection] = useState('profile');
    const [flotilias, setFlotilias] = useState(false);
    const [LVL, setLVL] = useState(window.lvl);
    const [XP, setXP] = useState(window.xp);

    const [newEvent, setNewEvent] = useState(undefined);
    const intervalRef = useRef(null);

    const [activeMapSection, setActiveMapSection] = useState('map');

    useEffect(() => {
        if (transitionState === 'fade-out') {
            const timer = setTimeout(() => {
                setTransitionState('fade-in');
            }, 150);

            return () => clearTimeout(timer);
        }
    }, [transitionState]);

    const handleChangeSection = (section) => {
        if (section === activeSection) {
            if (section !== 'map') {
                return;
            } else {
                if (activeMapSection !== 'map') {
                    setTransitionState('fade-out');
                    setTimeout(() => {
                        setActiveMapSection('map');
                    }, 150);
                }
            }
        }

        if (activeSection === 'map' && section !== 'map') {
            setActiveMapSection('map');
        }
            
        setTransitionState('fade-out');
        setTimeout(() => {
            setActiveSection(section);
        }, 150);
    };

    useEffect(() => {
        // Функция для проверки значения window.event_view
        const checkEventView = () => {
          if (window.event_view === null || (window.event_view && Object.keys(window.event_view).length === 0)) {
            setNewEvent(true);
            clearInterval(intervalRef.current);
          } else if (window.event_view[0] === true) {
            setNewEvent(false);
            clearInterval(intervalRef.current);
          }
        };
        intervalRef.current = setInterval(checkEventView, 500);
        return () => {
          clearInterval(intervalRef.current);
        };
    }, []);

    const renderSection = () => {
        switch (activeSection) {
            case 'profile':
                return <Profile setActiveSection={handleChangeSection} setStep={setStep} setTutorial={setTutorial} setLVL={setLVL} setXP={setXP} flotilias={flotilias} setFlotilias={setFlotilias} serverTime={serverTime}/>;
            /* case 'port':
                return <Port setActiveSection={handleChangeSection} setLVL={setLVL} setXP={setXP} serverTime={serverTime} />;
            case 'voyage':
                return <Voyage setActiveSection={handleChangeSection} setLVL={setLVL} setXP={setXP} serverTime={serverTime} />; */
            case 'map':
                return <MapNav activeMapSection={activeMapSection} setActiveMapSection={setActiveMapSection} setLVL={setLVL} setXP={setXP} serverTime={serverTime} />;    
            case 'market':
                return <Market setActiveSection={handleChangeSection}/>;
            case 'airdrop':
                return <Airdrop setActiveSection={handleChangeSection} />;
            default:
                return <Profile setActiveSection={handleChangeSection} setStep={setStep} setTutorial={setTutorial} setLVL={setLVL} setXP={setXP} flotilias={flotilias} setFlotilias={setFlotilias}/>;
        }
    };

    const getButtonClass = (section) => {
        return `nav-button ${activeSection === section ? 'active' : ''} ${section === 'map' && activeMapSection !== 'map' ? 'active-map' : ''}`;
    };

    return (
        <>
            <Header handleChangeSection={handleChangeSection} LVL={LVL} XP={XP} setFlotilias={setFlotilias} />
            <div className={`content ${transitionState}`}>
                {renderSection()}
            </div>
            <nav className='navigation'>
                <button className={getButtonClass('profile')} onClick={() => handleChangeSection('profile')}>
                    <div className='nav-img'><img src={ProfileIcon} alt="Profile"/></div>
                    <span className="navigation-text">{t('nav-profile')}</span>
                </button>
                {/* <button className={getButtonClass('port')} onClick={() => handleChangeSection('port')}>
                    <div className='nav-img'><img src={PortIcon} alt="Port"/></div>
                    <span className="navigation-text">{t('nav-port')}</span>
                </button>
                <button className={getButtonClass('voyage')} onClick={() => handleChangeSection('voyage')}>
                    <div className='nav-img'><img src={VoyageIcon} alt="Voyage"/></div>
                    <span className="navigation-text">{t('nav-trip')}</span>
                </button> */}

                <button className={getButtonClass('map')} onClick={() => handleChangeSection('map')}>
                    <div className='nav-img'><img src={MapIcon} alt="Map"/></div>
                    <span className="navigation-text">{t('nav-map')}</span>
                </button>

                <button className={getButtonClass('market')} onClick={() => handleChangeSection('market')}>
                    <div className='nav-img'><img src={MarketIcon} alt="Market"/></div>
                    <span className="navigation-text">{t('nav-market')}</span>
                </button>
                <button className={getButtonClass('airdrop')} onClick={() => handleChangeSection('airdrop')}>
                    <div className='nav-img'><img src={AirdropIcon} alt="Airdrop"/></div>
                    <span className="navigation-text airdrop">{t('nav-airdrop')}</span>
                </button>
            </nav>
        </>
    );
};

export default Navigation;

