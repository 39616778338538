import React, { useEffect, useState, useRef } from 'react';
import './mint-cont.scss';
import { useTranslate } from '../../translate/translate';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { toNano, beginCell, Address } from '@ton/core';
import { getMinted, changeWallet, getTimeServer } from '../../requests';
import Warning from '../../warnings';
import Alert from '../../alerts';

import BackImage from '../back.png';
import tonImage from '../../market/ton.png'
import ggImage from './gg.png'

import ContBlueImage from './blue.png';
import ContRedImage from './red.png';
import ContGreenImage from './green.png';
import ContBronzeImage from './bronze.png';
import ContSilverImage from './silver.png';
import ContGoldImage from './gold.png';

import plus from '../../plus.png';
import minus from '../../minus.png';
import sold_img from '../sold.png';

const contImages = {
    'Blue': ContBlueImage,
    'Red': ContRedImage,
    'Green': ContGreenImage,
    'Bronze': ContBronzeImage,
    'Silver': ContSilverImage,
    'Gold': ContGoldImage,
};

const contType = {
    'Blue': 0,
    'Red': 1,
    'Green': 2,
    'Bronze': 3,
    'Silver': 4,
    'Gold': 5,
};

const contPrices = {
    'Blue': 1,
    'Red': 1,
    'Green': 1,
    'Bronze': 1.5,
    'Silver': 2.5,
    'Gold': 3.5
};

const contSupply = {
    'Blue': 500,
    'Red': 500,
    'Green': 500,
    'Bronze': 300,
    'Silver': 200,
    'Gold': 150
};

const cashbackValue = {
    'Blue': 20,
    'Red': 20,
    'Green': 20,
    'Bronze': 20,
    'Silver': 30,
    'Gold': 30
};

const conts = Object.keys(contImages);

const MintContPage = ({ setMintPage }) => {
    const [tonConnectUI] = useTonConnectUI();
    const [isConnected, setIsConnected] = useState(false);
    const wallet = useTonAddress(false);
    const t = useTranslate();
    const [currentContType, setCurrentContType] = useState(conts[0]);
    const [selectNumber, setSelectNumber] = useState(0);
    const [isMinting, setIsMinting] = useState(false);
    const [contMinted, setContMinted] = useState(null);
    const [warning, setWarning] = useState(false);
    const [alert, setAlert] = useState(null);
    const [showPyro, setShowPyro] = useState(false);
    const [cashback, setCashback] = useState(false);


    //////////////////////////////////////// CASHBACK /////////////////////////////////
    const fetchServerTime = async () => {
        const time = await getTimeServer(); // Запрос на сервер
        const serverTimeMs = new Date(time).getTime();
        
        // Определяем начало и конец указанного диапазона
        const startDate = new Date('2024-11-11T00:00:00Z').getTime();
        const endDate = new Date('2024-11-11T23:59:59Z').getTime();

        // Проверяем, что время на сервере находится в пределах указанного диапазона
        if (serverTimeMs >= startDate && serverTimeMs <= endDate) {
            setCashback(true);
        }
    };

    useEffect(() => {
        fetchServerTime();
    }, []);
    ////////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        const fetch = async () => {
            const mintedContsRes = await getMinted(2); // 1 - корабли, 2 - контейнеры

            if (mintedContsRes === 'error') {
                setAlert('error-get-minted');
                setContMinted(null);
            } else {
                const mintedContsObj = mintedContsRes.reduce((acc, value, index) => {
                    acc[Object.keys(contType)[index]] = value;
                    return acc;
                }, {});
    
                const updatedContMinted = Object.keys(contSupply).map(type => {
                    return contSupply[type] - (mintedContsObj[type] || 0);
                });
    
                setContMinted(updatedContMinted);
            } 
        };

        fetch();
    }, []);

    const nextCont = () => {
        const currentIndex = conts.indexOf(currentContType);
        const nextIndex = (currentIndex + 1) % conts.length;
        setSelectNumber(0); 
        setCurrentContType(conts[nextIndex]);
    };

    const prevCont = () => {
        const currentIndex = conts.indexOf(currentContType);
        const prevIndex = (currentIndex - 1 + conts.length) % conts.length;
        setSelectNumber(0); 
        setCurrentContType(conts[prevIndex]);
    };

    const incrementNumber = () => {
        if (selectNumber < contMinted[contType[currentContType]]) {
            const newNumber = parseInt(selectNumber) + 1; 
            setSelectNumber(newNumber); 
        }
    };
    
    const decrementNumber = () => {
        if (parseInt(selectNumber) > 0) { 
            const newNumber = parseInt(selectNumber) - 1; 
            setSelectNumber(newNumber); 
        }
    };

    useEffect(() => {
        const checkWalletConnection = async () => {
          const isConnected = tonConnectUI.connected;
          if (isConnected) {
            setIsConnected(true); // Кошелек подключен
            
            if (window.wallet !== wallet) {
                const disconnectInfo = await changeWallet(window.tgID, wallet); // Присваивается window.wallet
                if (disconnectInfo === 'True') {
                    await tonConnectUI.disconnect();
                    setIsConnected(false);
                    window.wallet = undefined;
                    setWarning(true);
                } else if (disconnectInfo === 'error') {
                    setAlert('server-error');
                }
            }
          } else {
            setIsConnected(false); // Кошелек не подключен
          }
        };
    
        checkWalletConnection();
    }, [tonConnectUI.connected]);

    const mintCont = async (type, number) => {
        if (window.wallet) {
            setIsMinting(true);

            const amountSend = (contPrices[currentContType] + 0.2)*number;
            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                messages: [
                  {
                    address: 'EQAgILmPB2faA145DlhrJ1pmDF6RHaToe4AOaPrOchfnWNk_',
                    amount: String(toNano(amountSend)),
                    payload: beginCell()
                      .storeUint(0x322, 32)
                      .storeUint(type, 8) // type
                      .storeAddress(Address.parse(window.wallet)) //owner address
                      .storeUint(number, 64) // amount
                      .endCell().toBoc().toString('base64'),
                  },
                ],
            };
        
            tonConnectUI.sendTransaction(transaction).then(() => {
                setIsMinting(false);
                setSelectNumber(0); 

                setAlert('minted-cont');
                setShowPyro(true);
                setTimeout(() => {
                    setShowPyro(false);
                }, 3000);

            }).catch(() => {
                setIsMinting(false);
                setAlert('minted-close');
            })
        } else { await tonConnectUI.openModal(); }
        
    };

    return (
        <>
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null}

            {showPyro && (
                <div className="pyro">
                    <div className="before"></div>
                    <div className="after"></div>
                </div>
            )}

            {warning ? <Warning warning={warning} setWarning={setWarning}/> : null}
            <div className="mint-cont-overlay">
                <div className="mint-cont-header">
                    <button className="mint-cont-back" onClick={() => setMintPage(false)}>
                        <img src={BackImage} alt="Back" />
                    </button>
                    <span className="mint-cont-title">
                        {t("cont-mint")}
                    </span>
                    <button className="collection-link-button" onClick={() => window.Telegram.WebApp.openLink('https://getgems.io/collection/EQAgILmPB2faA145DlhrJ1pmDF6RHaToe4AOaPrOchfnWNk_')}>
                        <img src={ggImage} alt="Getgems" />
                    </button>
                </div>
                <div className="mint-slider-box">
                    <div className="mint-slider">
                        <button className="mint-cont-item" onClick={prevCont} style={{ transform: 'scale(0.8)', opacity: 0.8 }}>
                            <img src={contImages[conts[(conts.indexOf(currentContType) - 1 + conts.length) % conts.length]]} alt="Previous container" />
                            {contMinted && (contMinted[contType[conts[(conts.indexOf(currentContType) - 1 + conts.length) % conts.length]]] === 0) && <img className='sold-image' src={sold_img} alt="Sold" />}
                            <div className="mint-cont-slider-info">
                                <span className="mint-cont-slider-name">{t("type")}: {conts[(conts.indexOf(currentContType) - 1 + conts.length) % conts.length]}</span>
                                {contMinted && <div className={`mint-cont-slider-supply ${contMinted ? 'ready' : ''}`}>Supply: {contMinted[contType[conts[(conts.indexOf(currentContType) - 1 + conts.length) % conts.length]]]}/{contSupply[conts[(conts.indexOf(currentContType) - 1 + conts.length) % conts.length]]}</div>}
                                <div className="mint-cont-slider-price">
                                    <span>{t("price")}: {contPrices[conts[(conts.indexOf(currentContType) - 1 + conts.length) % conts.length]]}</span>
                                    <img src={tonImage} alt="TON" />
                                </div>
                            </div>

                            {cashback &&
                                <div className="cashback">
                                    <span>CASHBACK</span>
                                    <span className="percent">{cashbackValue[conts[(conts.indexOf(currentContType) - 1 + conts.length) % conts.length]]}%</span>
                                </div>
                            }

                        </button>
                        <button className="mint-cont-item">
                            <img src={contImages[currentContType]} alt="Current container" />
                            {contMinted && (contMinted[contType[currentContType]] === 0) && <img className='sold-image' src={sold_img} alt="Sold" />}
                            <div className="mint-cont-slider-info">
                                <span className="mint-cont-slider-name">{t("type")}: {currentContType}</span>
                                {contMinted && <div className={`mint-cont-slider-supply ${contMinted ? 'ready' : ''}`}>Supply: {contMinted[contType[currentContType]]}/{contSupply[currentContType]}</div>}
                                <div className="mint-cont-slider-price">
                                    <span>{t("price")}: {contPrices[currentContType]}</span>
                                    <img src={tonImage} alt="TON" />
                                </div>
                            </div>

                            {cashback &&
                                <div className="cashback">
                                    <span>CASHBACK</span>
                                    <span className="percent">{cashbackValue[currentContType]}%</span>
                                </div>
                            }

                        </button>
                        <button className="mint-cont-item" onClick={nextCont} style={{ transform: 'scale(0.8)', opacity: 0.8 }}>
                            <img src={contImages[conts[(conts.indexOf(currentContType) + 1) % conts.length]]} alt="Next container" />
                            {contMinted && (contMinted[contType[conts[(conts.indexOf(currentContType) + 1 + conts.length) % conts.length]]] === 0) && <img className='sold-image' src={sold_img} alt="Sold" />}
                            <div className="mint-cont-slider-info">
                                <span className="mint-cont-slider-name">{t("type")}: {conts[(conts.indexOf(currentContType) + 1) % conts.length]}</span>
                                {contMinted && <div className={`mint-cont-slider-supply ${contMinted ? 'ready' : ''}`}>Supply: {contMinted[conts[(conts.indexOf(currentContType) + 1) % conts.length]]}/{contSupply[conts[(conts.indexOf(currentContType) + 1) % conts.length]]}</div>}
                                <div className="mint-cont-slider-price">
                                    <span>{t("price")}: {contPrices[conts[(conts.indexOf(currentContType) + 1) % conts.length]]}</span>
                                    <img src={tonImage} alt="TON" />
                                </div>
                            </div>

                            {cashback &&
                                <div className="cashback">
                                    <span>CASHBACK</span>
                                    <span className="percent">{cashbackValue[conts[(conts.indexOf(currentContType) + 1) % conts.length]]}%</span>
                                </div>
                            }

                        </button>
                    </div>
                </div>

                <div className="mint-cont-number-box">
                    <button onClick={decrementNumber}>
                        <img src={minus} alt="-" />
                    </button>
                    <div className="mint-cont-number-input">{selectNumber}</div>
                    <button onClick={incrementNumber}>
                        <img src={plus} alt="+" />
                    </button>
                </div>

                <button className={`mint-cont-button ${selectNumber == 0 || isMinting ? 'disabled' : ''}`} onClick={() => mintCont(contType[currentContType], selectNumber)}>
                    {isMinting ? (
                        <div className="loading-animation-minting">
                            <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div>
                    ) : (
                        selectNumber == 0 ? (
                            t("mint-nft")
                        ) : (
                            <>
                                {t("mint-nft")} (
                                {contPrices[currentContType] * selectNumber}
                                <img src={tonImage} alt="TON" />)
                            </>
                        )
                    )} 
                </button>
                <span className="detention-conts">{t("detention")}</span>
            </div>  
        </>
    );
};

export default MintContPage;