import React, { useState, useEffect } from 'react';
import './cont.scss';
import { useTranslate } from '../../translate/translate';
import { getContainers } from '../../requests';
import Alert from '../../alerts';

import ContBlueImage from './blue.png';
import ContRedImage from './red.png';
import ContGreenImage from './green.png';
import ContBronzeImage from './bronze.png';
import ContSilverImage from './silver.png';
import ContGoldImage from './gold.png';

import BackImage from '../back.png';
import config from './cont_upgrade.json';
import UpgradeCont from './upgrade';

import MintContPage from './mint-cont';

const Cont = ({ setActiveSection }) => {
    const t = useTranslate();
    const [containers, setContainers] = useState([]);
    const [currentCont, setCurrentCont] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [mintPage, setMintPage] = useState(false);
    const [modalUpgrade, setModalUpgrade] = useState(false);
    const [alert, setAlert] = useState(null);

    const fetchContainers = async () => {
        const response = await getContainers(window.tgID, window.wallet);

        if (response == 'error') {
            setAlert('server-error');
        }

        setDataLoaded(true);

        const configContainers = config.containers;
        const updatedContainers = [
            {id: 0, name: 'blue-cont', image: ContBlueImage, amount: window.cont_blue, level: window.lvl_conts[0]}, 
            {id: 1, name: 'red-cont', image: ContRedImage, amount: window.cont_red, level: window.lvl_conts[1]}, 
            {id: 2, name: 'green-cont', image: ContGreenImage, amount: window.cont_green, level: window.lvl_conts[2]}, 
            {id: 3, name: 'bronze-cont', image: ContBronzeImage, amount: window.cont_bronze, level: window.lvl_conts[3]}, 
            {id: 4, name: 'silver-cont', image: ContSilverImage, amount: window.cont_silver, level: window.lvl_conts[4]}, 
            {id: 5, name: 'gold-cont', image: ContGoldImage, amount: window.cont_gold, level: window.lvl_conts[5]}
        ].map(cont => {
            const configCont = configContainers.find(c => c.id === cont.name);
            if (configCont) {
                const levelConfig = configCont.levels.find(l => l.level === cont.level);
                if (levelConfig) {
                    return { ...cont, bonus: levelConfig.bonus_res, resources: levelConfig.requirements };
                }
            }
            return cont;
        });
        setContainers(updatedContainers);
    };

    useEffect(() => {
        fetchContainers();
    }, []);

    const selectContainer = (cont) => {
        setCurrentCont(cont);
        setModalUpgrade(true);
    }

    return (
        <>
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null}

            {mintPage ? (<MintContPage setMintPage={setMintPage}/>) : null}

            {modalUpgrade ? <UpgradeCont modalUpgrade={modalUpgrade} setModalUpgrade={setModalUpgrade}
                currentCont={currentCont} setCurrentCont={setCurrentCont} config={config} setContainers={setContainers} /> : null}

            <div className="cont-header">
                <button className="cont-back-button" onClick={() => setActiveSection('port')}>
                    <img src={BackImage} alt="Back" />
                </button>
                <span className="cont-title">{t('title-cont')}</span>
                <button className="buy-cont-button" onClick={() => setMintPage(true)}>{t('buy')} </button>
            </div>

            <div className="containers-box">
                {containers ? containers.map((cont, index) => (
                    <div className='container-box' key={index}>
                        <div className="left-side">
                            <img src={cont.image} alt={cont.name} />
                            <div className="cont-level-box">{cont.level} {t('lvl')}</div>
                            <div className="amount-cont">{cont.amount}</div>
                        </div>

                        <div className="right-side">
                            {dataLoaded ? (
                                <>
                                    <div className="cont-resources-box">{t('bonus')}: +{cont.bonus}%</div>
                                    {cont.level != 10 && <button className="cont-upgrade-button" onClick={() => selectContainer(cont)}>{t('upgrade')}</button>}     
                                </>
                            ) : (
                                <div className="loading-animation-cont">
                                    <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </div>
                            )}
                        </div> 
                    </div>
                )) : 
                    <div className="loading-animation-conts">
                        <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                        </svg>
                    </div>
                }
            </div>
            
        </>
    );
};

export default Cont;
