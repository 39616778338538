
import React, { useState, useEffect } from 'react';
import './store.scss';
import { useTranslate } from '../../translate/translate';
import { format, formatCapacity } from '../../format-number';
import config_store from './config_store.json';
import UpgradeStore from './upgrade';
import Alert from '../../alerts';

import BackImage from '../back.png';

import { refreshItems } from '../../requests'

import Resource1Image from '../../resources/1.png';
import Resource2Image from '../../resources/2.png';
import Resource3Image from '../../resources/3.png';
import Resource4Image from '../../resources/4.png';
import Resource5Image from '../../resources/5.png';
import Resource6Image from '../../resources/6.png';
import Resource7Image from '../../resources/7.png';
import Resource8Image from '../../resources/8.png';
import Resource9Image from '../../resources/9.png';
import Resource10Image from '../../resources/10.png';
import Resource11Image from '../../resources/11.png';
import Resource12Image from '../../resources/12.png';
import Resource13Image from '../../resources/13.png';
import Resource14Image from '../../resources/14.png';

import Component1Image from '../../components/1.png';
import Component2Image from '../../components/2.png';
import Component3Image from '../../components/3.png';
import Component4Image from '../../components/4.png';
import Component5Image from '../../components/5.png';
import Component6Image from '../../components/6.png';
import Component7Image from '../../components/7.png';
import Component8Image from '../../components/8.png';
import Component9Image from '../../components/9.png';
import Component10Image from '../../components/10.png';
import Component11Image from '../../components/11.png';
import Component12Image from '../../components/12.png';
import Component13Image from '../../components/13.png';
import Component14Image from '../../components/14.png';
import Component15Image from '../../components/15.png';
import Component16Image from '../../components/16.png';
import Component17Image from '../../components/17.png';
import Component18Image from '../../components/18.png';
import Component19Image from '../../components/19.png';
import Component20Image from '../../components/20.png';
import Component21Image from '../../components/21.png';

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const Store = ({ setActiveSection }) => {
    const t = useTranslate();
    const [upgradeModal, setUpgradeModal] = useState(false);
    const [rowCountResources, setRowCountResources] = useState(1);
    const [availableResources, setAvailableResources] = useState({});
    const [rowCountComponents, setRowCountComponents] = useState(1);
    const [availableComponents, setAvailableComponents] = useState({});
    const [rowCountTechnologies, setRowCountTechnologies] = useState(1);
    const [availableTechnologies, setAvailableTechnologies] = useState({});
    const [capacityResources, setCapacityResources] = useState([]);
    const [capacityComponents, setCapacityComponents] = useState([]);

    const [alert, setAlert] = useState(null);
    const [showPyro, setShowPyro] = useState(false);

    const refreshData = async () => {
        if (window.tgID) {
            const response = await refreshItems(window.tgID);

            if (response === 'error') {
                setAlert('server-error');
            } else if (response === 'True') {
                const storeLevel = config_store.store.find(store => store.lvl == window.lvl_store);
                if (storeLevel) {
                    setCapacityResources(storeLevel.capacity[0].resources);
                    setCapacityComponents(storeLevel.capacity[0].components);
                }

                if (Array.isArray(window.resources)) {
                    const countResources = window.resources.filter(item => item > 0).length;
                    setRowCountResources(Math.ceil(countResources / 5));
                }
                const newResourceImagesMap = {};
                window.resources.forEach((item, index) => {
                    if (item > 0) {
                        newResourceImagesMap[index+1] = {image: resourceImages[index + 1], value: item};
                    }
                });
                setAvailableResources(newResourceImagesMap);

                if (Array.isArray(window.components)) {
                    const countComponents = window.components.filter(item => item > 0).length;
                    setRowCountComponents(Math.ceil(countComponents / 5));
                }
                const newComponentImagesMap = {};
                window.components.forEach((item, index) => {
                    if (item > 0) {
                        newComponentImagesMap[index+1] = {image: componentImages[index + 1], value: item};
                    }
                });
                setAvailableComponents(newComponentImagesMap);
            }
        }
    };

    useEffect(() => {
        refreshData();
    }, []);

    const renderCells = (type) => {
        const cells = [];
        let Array = [];
        let rowCount;
        if (type === 'Resources') {
            Array = Object.entries(availableResources).map(([key, value]) => ({ key, ...value }));
            rowCount = rowCountResources;
        } else if (type === 'Components') {
            Array = Object.entries(availableComponents).map(([key, value]) => ({ key, ...value }));
            rowCount = rowCountComponents;
        } else if (type === 'Technologies') {
            Array = Object.entries(availableTechnologies).map(([key, value]) => ({ key, ...value }));
            rowCount = rowCountTechnologies;
        }
        Array.sort((a, b) => parseInt(a.key) - parseInt(b.key));
        
        for (let i = 0; i < rowCount * 5; i++) {
            const element = Array[i];
            if (element) {
                cells.push(
                    <div key={i} className="store-cell">
                        <div className="store-cell-img">
                            <img src={element.image} alt={`${type === 'Resources' ? '' : type === 'Components' ? "" : 'Technologies'} ${element.key}`} />
                        </div>
                        <div className={`store-cell-value ${(type === 'Resources' && element.value >= capacityResources[element.key-1]) || (type === 'Components' && element.value >= capacityComponents[element.key-1]) ? 'full' : ''}`}>
                            {type === 'Resources' ? 
                                `${format(element.value)}/${formatCapacity(capacityResources[element.key-1])}` 
                                : type === 'Components' ? 
                                    `${element.value}/${formatCapacity(capacityComponents[element.key-1])}`
                                    : null
                            }
                        </div>
                    </div>
                );
            } else {
                cells.push(
                    <div key={i} className="store-cell"></div>
                );
            }
        }
        return cells;
    };

    return (
        <>
            {alert ? <Alert alert={alert} setAlert={setAlert} /> : null}
            {upgradeModal ? <UpgradeStore upgradeModal={upgradeModal} setUpgradeModal={setUpgradeModal} resourceImages={resourceImages}
                componentImages={componentImages} config_store={config_store} refreshData={refreshData} setShowPyro={setShowPyro}
                setAlert={setAlert}/> 
            : null}
            <div className="store-header">
                <button className="store-back-button" onClick={() => setActiveSection('port')}>
                    <img src={BackImage} alt="Back" />
                </button>
                <div className="store-title">
                    <span className="store-title1">{t('store')}</span>
                    <div className="store-title2">{window.lvl_store} {t('lvl')}</div>
                </div>
                {window.lvl_store != 10 ? 
                    <button className="store-button-upgrade" onClick={() => setUpgradeModal(true)}>{t('upgrade')}</button>
                    : null
                }

                {showPyro && (
                    <div className="pyro">
                        <div className="before"></div>
                        <div className="after"></div>
                    </div>
                )}
            </div>

            <div className="store-boxes">
                <div className="store-resources-box">
                    <span className="store-box-title">{t('resources')}</span>
                    <div className="store-box">
                        {renderCells('Resources')}
                    </div>
                </div>

                <div className="store-components-box">
                    <span className="store-box-title">{t('components')}</span>
                    <div className="store-box">
                        {renderCells('Components')}
                    </div>
                </div>

                <div className="store-technology-box">
                    <span className="store-box-title">{t('technologies')}</span>
                    <div className="store-box">
                        
                    </div>
                </div>
            </div> 
        </>
    );
};

export default Store;