
import React, { useEffect, useState } from 'react';
import './map.scss';
import { useTranslate } from '../translate/translate';

import SoonAlert from '../technologies/soon'

import MapImage from './map.jpg';
import MapPortIcon from './port-icon.jpg';
import MapAutodromeIcon from './autodrome.jpg';
import MapAirportIcon from './airport.jpg';
import MapCosmodromeIcon from './cosmodrome.jpg';

import LockImage from '../voyage/lock.png'

const Map = ({ handleChangeMapSection }) => {
    const t = useTranslate();
    const [alert, setAlert] = useState(null);
    const [soonAlert, setSoonAlert] = useState(null);

    const soonClick = (event) => {
        const { clientX, clientY } = event;
        setSoonAlert({ page: 'header', x: clientX, y: clientY });
    };

    return (
        <>
            {soonAlert && <SoonAlert soonAlert={soonAlert} setSoonAlert={setSoonAlert}/>}

            <div className="map-content">

                <div className="map">
                    <img className="map-image" src={MapImage} alt="Map" />

                    <div id="map-marker-port" onClick={() => handleChangeMapSection('seaport')}>
                        <div className="image-box"><img src={MapPortIcon} alt="Seaport" /></div>
                        <div className="marker-name">{t("seaport")}</div>
                    </div>

                    <div id="map-marker-autodrome" onClick={soonClick}>
                        <div className="image-box"><img src={MapAutodromeIcon} alt="Autodrome" /></div>
                        <div className="map-soon"><img src={LockImage} alt="" /></div>
                        <div className="marker-name">{t("autodrome")}</div>
                    </div>

                    <div id="map-marker-airport" onClick={soonClick}>
                        <div className="image-box"><img src={MapAirportIcon} alt="Airport" /></div>
                        <div className="map-soon"><img src={LockImage} alt="" /></div>
                        <div className="marker-name">{t("airport")}</div>
                    </div>

                    <div id="map-marker-cosmodrome" onClick={soonClick}>
                        <div className="image-box"><img src={MapCosmodromeIcon} alt="Cosmodrome" /></div>
                        <div className="map-soon"><img src={LockImage} alt="" /></div>
                        <div className="marker-name">{t("cosmodrome")}</div>
                    </div>

                    {/* <div id="map-marker-autodrome" onClick={() => handleChangeMapSection('autodrome')}>
                        <div className="image-box"><img src={MapAutodromeIcon} alt="Autodrome" /></div>
                        <div className="marker-name">{t("autodrome")}</div>
                    </div>

                    <div id="map-marker-airport" onClick={() => handleChangeMapSection('airport')}>
                        <div className="image-box"><img src={MapAirportIcon} alt="Airport" /></div>
                        <div className="marker-name">{t("airport")}</div>
                    </div>

                    <div id="map-marker-cosmodrome" onClick={() => handleChangeMapSection('cosmodrome')}>
                        <div className="image-box"><img src={MapCosmodromeIcon} alt="Cosmodrome" /></div>
                        <div className="marker-name">{t("cosmodrome")}</div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default Map;