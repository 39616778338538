import React, { useState, useEffect } from 'react';
import "./settings.scss";
import ReactDOM from 'react-dom';
import { useTranslate } from '../translate/translate';
import { changeSettings } from '../requests';

import { useTranslation } from 'react-i18next'
import LangIconEn from '../translate/en.png';
import LangIconRu from '../translate/ru.png';
import LangIconThai from '../translate/thai.png';
import arrowImage from '../navigation/arrow-down.png'

const languageIcon = {
    'ru': LangIconRu,
    'en': LangIconEn,
    'thai': LangIconThai
};

const Settings = ({ settings, setSettings, setAlert }) => {
    const t = useTranslate();
    const { i18n } = useTranslation();
    const [notice, setNotice] = useState(window.notice);
    const [currentLanguage, setCurrentLanguage] = useState(window.lang);
    const [isSwap, setIsSwap] = useState(false);

    const handleSwapClick = () => {
        setIsSwap(!isSwap);
    };

    const swapLanguage = (language) => {
        i18n.changeLanguage(language);
        setCurrentLanguage(language);
    };

    const changeNotice = (type, newValue) => {
        setNotice(prevNotice => prevNotice.map((value, i) => i === type ? newValue : value));
    };

    const saveAndCloseSettings = async () => {
        if (window.lang !== currentLanguage || window.notice !== notice) {
            const response = await changeSettings(window.tgID, currentLanguage, notice);
            if (response === 'error') {
                setAlert('server-error');
            } else {
                window.lang = currentLanguage;
                window.notice = notice;
            }
        }
        setSettings(false);
    };

    return (
        <>      
            {settings && (
                ReactDOM.createPortal(
                    <div className="settings-overlay" onClick={() => saveAndCloseSettings()}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">{t('settings')}</div>

                            <div className="language-box">
                                <span className="language-title">{t('language')}</span>

                                <div className="overlay">
                                    <div className="langImage-box">
                                        <img src={languageIcon[currentLanguage]} alt={currentLanguage} />
                                    </div>

                                    <div className={`language-switch-box ${isSwap ? 'swap' : ''}`} onClick={handleSwapClick}>
                                        <div className="button-content">
                                            <span>{currentLanguage === 'en' ? 'English' : currentLanguage === 'ru' ? 'Русский' : currentLanguage === 'thai' ? 'แบบไทย' : null}</span>
                                            <img src={arrowImage} alt="Swap" />
                                        </div>

                                        {isSwap && (
                                            <div className="options-box">
                                                <button onClick={() => swapLanguage('en')}>English</button>
                                                <button onClick={() => swapLanguage('ru')}>Русский</button>
                                                <button onClick={() => swapLanguage('thai')}>แบบไทย</button>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>

                            <div className="notice-box">
                                <span className="notice-title">{t('notices')}</span>

                                <div className="notice">
                                    <span>{t('notice1')}</span>
                                    <button className="switch-box" onClick={() => notice[0] === 1 ? changeNotice(0, 0) : changeNotice(0, 1)}>
                                        <div className={`switch-element ${notice[0] === 1 ? 'true' : 'false'} `}></div>
                                    </button>
                                </div>

                                <div className="notice">
                                    <span>{t('notice2')}</span>
                                    <button className="switch-box" onClick={() => notice[1] === 1 ? changeNotice(1, 0) : changeNotice(1, 1)}>
                                        <div className={`switch-element ${notice[1] === 1 ? 'true' : 'false'} `}></div>
                                    </button>
                                </div>

                                <div className="notice">
                                    <span>{t('notice3')}</span>
                                    <button className="switch-box" onClick={() => notice[2] === 1 ? changeNotice(2, 0) : changeNotice(2, 1)}>
                                        <div className={`switch-element ${notice[2] === 1 ? 'true' : 'false'} `}></div>
                                    </button>
                                </div>

                                <div className="notice">
                                    <span>{t('notice4')}</span>
                                    <button className="switch-box" onClick={() => notice[3] === 1 ? changeNotice(3, 0) : changeNotice(3, 1)}>
                                        <div className={`switch-element ${notice[3] === 1 ? 'true' : 'false'} `}></div>
                                    </button>
                                </div>

                            </div>
                            
                        </div>
                    </div>,
                    document.body
                ) 
            )}
        </>
    );
};

export default Settings;