import React, { useState, useEffect, useRef } from 'react';
import "./claim-modal.scss";
import ReactDOM from 'react-dom';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { toNano, beginCell } from '@ton/core';
import Warning from '../../warnings';
import { useTranslate } from '../../translate/translate';
import X2Image from './x2.png';
import Resource2Image from '../../resources/2.png';
import TonImage from '../../market/ton.png';
import { rigClaimTon, changeWallet } from '../../requests';
import TonWeb from "tonweb";

const ClaimModal = ({ modalClaim, setModalClaim, claim, loadClaim, setLoadClaim, currentFill, getParams, claimTon, config_store, setAlert, loadCommonClaim }) => {

    const t = useTranslate();
    const [amountFuel, setAmountFuel] = useState(null);
    const [tonConnectUI] = useTonConnectUI();
    const [isConnected, setIsConnected] = useState(false);
    const wallet = useTonAddress(false);
    const [warning, setWarning] = useState(false);
    const [loadTonButton, setLoadTonButton] = useState(false);

    useEffect(() => {
        if (currentFill[modalClaim] > getParams(modalClaim, 'capacity')) {
            setAmountFuel(getParams(modalClaim, 'capacity').toFixed(2));
        } else {
            setAmountFuel(currentFill[modalClaim].toFixed(2));
        }
    }, []);

    useEffect(() => {
        const checkWalletConnection = async () => {
          const isConnected = tonConnectUI.connected;
          if (isConnected) {
            setIsConnected(true); // Кошелек подключен
            
            if (window.wallet !== wallet) {
                const disconnectInfo = await changeWallet(window.tgID, wallet); // Присваивается window.wallet
                if (disconnectInfo === 'True') {
                    await tonConnectUI.disconnect();
                    setIsConnected(false);
                    window.wallet = undefined;
                    setWarning(true);
                } else if (disconnectInfo === 'error') {
                    setAlert('server-error');
                }
            }
          } else {
            setIsConnected(false); // Кошелек не подключен
          }
        };
    
        checkWalletConnection();
      }, [tonConnectUI.connected]);

    function generateID() {
        const randomNumber = Math.floor(100000000000 + Math.random() * 900000000000);
        return randomNumber;
    }

    const claimTonFunc = async (rig) => {
        setLoadTonButton(true);
        setLoadClaim(prev => ({ ...prev, [rig]: true }));

        if (window.wallet) {
            const capacityFuel = config_store.store.find(store => store.lvl === window.lvl_store).capacity[0].resources[1];
            if (window.resources[1] + (amountFuel*2) > capacityFuel) {
                setAlert('component-full');
                setLoadTonButton(false);
            } else {
                const transactionID = generateID();

                const body = beginCell()
                .storeUint(0, 32)
                .storeStringTail(`Container Rush: Claim X2 fuel (${transactionID})`)
                .endCell();
    
                const transaction = {
                    validUntil: Math.floor(Date.now() / 1000) + 30,
                    messages: [
                        {
                            address: 'UQD6K__rz2PeYCGfBLwHhQPe_oEp-rwYM33zmb-ysiKeMcts',
                            amount: toNano(0.008).toString(),
                            payload: body.toBoc().toString("base64")
                        }
                    ]
                };

                if (transactionID) {
                    const response = await rigClaimTon(window.tgID, rig, transactionID);

                    if (response === 'error') {
                        setAlert('server-error');
                        setLoadTonButton(false);
                        setLoadClaim(prev => ({ ...prev, [rig]: false }));
                    } else if (response === 'True') {
                        try {
                            await tonConnectUI.sendTransaction(transaction);
                            claimTon(rig);
                        } catch { // Транзакция отменена
                            setLoadTonButton(false);
                            setLoadClaim(prev => ({ ...prev, [rig]: false }));
                            setAlert('market-buy-close');
                        }
                    }
                }
            }
            
        } else { 
            await tonConnectUI.openModal(); 
            setLoadTonButton(false);
        }
    };

    return (
        <>      
            {warning ? <Warning warning={warning} setWarning={setWarning}/> : null}
            {modalClaim ? (
                ReactDOM.createPortal(
                    <div className="claim-rig-overlay" onClick={() => {
                        if (!loadTonButton && !loadCommonClaim) {
                          setModalClaim(null);
                        }
                      }}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <img className="x2" src={X2Image} alt="X2" />
                            <div className="claim-info">
                                <span className="title">{t('claim-rig1')}</span>
                                <span className="info">{t('claim-rig2')}</span>
                            </div>
                            <div className="buttons">
                                <button className={`claim ${loadCommonClaim || loadTonButton ? 'disabled' : ''}`} disabled={loadCommonClaim || loadTonButton} onClick={() => claim(modalClaim)}>
                                    {!loadCommonClaim ? // && !loadTonButton
                                        <>
                                        <span>{amountFuel}</span>
                                        <img src={Resource2Image} alt="Fuel" />
                                        </>
                                        :
                                        <div className="loading-claim">
                                            <svg className="spinner" strokeWidth="65px" height="70%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                            </svg>
                                        </div>
                                    }
                                </button>
                                <button className={`claim ton ${loadCommonClaim || loadTonButton ? 'disabled-ton' : ''}`} disabled={loadCommonClaim || loadTonButton} onClick={() => claimTonFunc(modalClaim)}>
                                    {!loadTonButton && !loadCommonClaim ? 
                                        <>
                                        <div className="part1">
                                            <img src={TonImage} alt="TON" />
                                        </div>
                                        <div className="part2">
                                            <span>{(amountFuel*2).toFixed(2)}</span>
                                            <img src={Resource2Image} alt="Fuel" />
                                        </div>
                                        </>
                                        :
                                        <div className="loading-claim">
                                            <svg className="spinner" strokeWidth="65px" height="70%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                            </svg>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default ClaimModal;