
import React, { useState, useEffect } from 'react';
import './sell-tab.scss';
import { useTranslate } from '../translate/translate';
import { marketGetMyLots, getInfoLot, cancelLot } from '../requests'
import { format, formatMarket } from '../format-number';
import TonImage from './ton.png';
import Alert from '../alerts';
import ModalSell from './sell-modal';

const SellTab = ({ resourceImages, componentImages, resourceMinimum }) => {
  const t = useTranslate();
  const [sellModal, setSellModal] = useState(null);
  const [alert, setAlert] = useState(null);
  const [tab, setTab] = useState('all');
  const [loadingData, setLoadingData] = useState(true);
  const componentsMinimum = 1;
  const [resources, setResources] = useState(null);
  const [components, setComponents] = useState(null);
  const [myLots, setMyLots] = useState([]);
  const [loadingCancel, setLoadingCancel] = useState([]);

  const fetchData = async () => {
    setLoadingData(true);

    const lots = await marketGetMyLots(window.tgID);

    if (lots === 'error') {
      setAlert('server-error');
    } else {
      setResources(window.resources.map((value, index) => value - window.resources_market[index]));
      setComponents(window.components.map((value, index) => value - window.components_market[index]));
  
      setMyLots(lots);
  
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderResources = () => {
    return resources.map((count, index) => {
      if (count > 0) {
        return (
          <button key={index} className="element-button" onClick={() => setSellModal({type: 'resources', number: index, count: count})}>
            <img src={resourceImages[index + 1]} alt={`Resource ${index + 1}`} />
            <span>{format(count)}</span>
          </button>
        );
      }
      return null;
    });
  };

  const renderComponents = () => {
    return components.map((count, index) => {
      if (count > 0) {
        return (
          <button key={index} className="element-button" onClick={() => setSellModal({type: 'components', number: index, count: count})}>
            <img src={componentImages[index + 1]} alt={`Component ${index + 1}`} />
            <span>{formatMarket(count)}</span>
          </button>
        );
      }
      return null;
    });
  };

  const calculatePrice = (type, price, value, number) => {  
    const result = (price / value * (type === 'resources' ? resourceMinimum[number] : componentsMinimum)).toFixed(5);
    return parseFloat(result).toString();
  };

  const cancelMyLot = async (id) => {
    setLoadingCancel(prevState => [...prevState, id]);
    try {
      const availableLot = await cancelLot(id);

      if (availableLot == 'error') {
        setAlert('server-error');
      } else if (availableLot == 'sold') {
        setAlert('no-cancel-lot');
      } else if (availableLot == 'success') {
        fetchData();
        setAlert('lot-canceled');
      }
    } finally {
      setLoadingCancel(prevState => prevState.filter(item => item !== id));
    }
  };

  return (
    <>
      {alert ? <Alert alert={alert} setAlert={setAlert} /> : null} 
      {sellModal && <ModalSell sellModal={sellModal} setSellModal={setSellModal} resourceImages={resourceImages} componentImages={componentImages} resourceMinimum={resourceMinimum} setAlert={setAlert} componentsMinimum={componentsMinimum} setMyLots={setMyLots}
        setResources={setResources} setComponents={setComponents} />}
      <div className='market-sell'>
        <div className='market-sell-store'>
          <div className='sell-switch-box'>
            <button 
                className={`switch-button1 ${tab === 'all' ? 'active' : ''}`} 
                onClick={() => setTab('all')}
                disabled={tab === 'all'}
            >
                {t('all')}
            </button>
            <button 
                className={`switch-button2 ${tab === 'resources' ? 'active' : ''}`} 
                onClick={() => setTab('resources')}
                disabled={tab === 'resources'}
            >
                {t('resources')}
            </button>
            <button 
                className={`switch-button3 ${tab === 'components' ? 'active' : ''}`} 
                onClick={() => setTab('components')}
                disabled={tab === 'components'}
            >
                {t('components')}
            </button>

          </div>

          <div className='store'>

            {!loadingData ? (
              tab === 'resources' ? renderResources() :
              tab === 'components' ? renderComponents() :
              <>
                {renderResources()}
                {renderComponents()}
              </>
            ) : 
              <div className="loading-animation-sell-list">
                <svg className="spinner" strokeWidth="65px" height="50%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
              </div>
            }
          </div>
        </div>


        <div className='my-lots'>
            <span className='title'>{t('my-lots')}</span>
            <div className='my-lots-list'>

              {myLots.map((lot, index) => (
                <div className="my-lot-item" key={index}>

                  <div className="img-box">
                    <img src={lot.type === 'resources' ? resourceImages[((lot.number - 1 + 14) % 14) + 1] : componentImages[((lot.number - 1 + 21) % 21) + 1]} alt={`Lot ${index}`} />
                    <div className="value-box">
                      <span>
                        {formatMarket(lot.value)}
                      </span>
                    </div>
                  </div>

                  <div className="info-box">
                    <div className="price-box">
                      <span className="title">{t('price')} ({lot.type === 'resources' ? format(resourceMinimum[lot.number]) : componentsMinimum})</span>
                      <div className="price">
                        <span> {calculatePrice(lot.type, lot.price, lot.value, lot.number)}</span>
                        <img src={TonImage} alt="TON" />
                      </div>
                    </div>

                    <button className={`cancel-button ${loadingCancel.includes(lot.id) ? 'disabled' : ''}`} disabled={loadingCancel.includes(lot.id)} onClick={() => cancelMyLot(lot.id)}>
                      {!loadingCancel.includes(lot.id) ? t('cancel') : 
                        <div className="loading-animation-cancel">
                          <svg className="spinner" strokeWidth="65px" height="75%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                          </svg>
                        </div>
                      }
                    </button>
                  </div>             
                </div>
              ))}

            </div>
        </div>

      </div>
    </>
  );
};

export default SellTab;