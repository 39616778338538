import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './referal.scss';

import { format } from '../format-number';
import { useTranslate } from '../translate/translate';

import ReferalIcon from './referal.png';
import BackImage from '../port/back.png';

import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';
import Resource3Image from '../resources/3.png';
import Resource4Image from '../resources/4.png';
import Resource5Image from '../resources/5.png';
import Resource6Image from '../resources/6.png';
import Resource7Image from '../resources/7.png';
import Resource8Image from '../resources/8.png';
import Resource9Image from '../resources/9.png';
import Resource10Image from '../resources/10.png';
import Resource11Image from '../resources/11.png';
import Resource12Image from '../resources/12.png';
import Resource13Image from '../resources/13.png';
import Resource14Image from '../resources/14.png';

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const Referal = () => {
    const t = useTranslate();
    const [openWindow, setOpenWindow] = useState(false);
    const [shareType, setShareType] = useState(false);
    const [copyButtonText, setCopyButtonText] = useState("frens6");
    const ref_link = `https://t.me/containerrush_bot/app?startapp=${window.id}`;

    function shareSend() {
        const message = 'Join the Container Rush!\nSet sail and earn money!\n';
        const encodedMessage = encodeURIComponent(message);
        window.location.href = `https://t.me/share/url?url=${encodedMessage}${ref_link}`;
    }

    function shareCopy() {
        navigator.clipboard.writeText(ref_link);
        setCopyButtonText(t("frens7"));
        setTimeout(() => {
            setCopyButtonText(t("frens6"));
        }, 5000);
    }

    function closeRefsWindow() {
        setShareType(false);
        setOpenWindow(false);
    }

    return (
        <>
            {openWindow ? (
                <>
                <div className="ref-overlay">
                    <button className="ref-back" onClick={closeRefsWindow}>
                        <img src={BackImage} alt="Back" />
                    </button>
                    <span className="ref-title">
                        {t("frens")}
                    </span>
                    <div className="ref-share-box">
                        <span className="ref-share-box-info">{t("frens1")}</span>

                        {shareType ? (
                            <div className="ref-share-buttons">
                                <button className="ref-share-button-copy" onClick={shareCopy}>
                                    {t(copyButtonText)}
                                </button>
                                <button className="ref-share-button-share" onClick={shareSend}>
                                    {t("frens2")}
                                </button>
                            </div>
                            
                        ) : (
                            <button className="ref-share-button" onClick={() => setShareType(true)}>
                                {t("frens3")}
                            </button>
                        )}
                    </div>
                    <div className="ref-info-box">
                        {t("frens4")}
                    </div>
                    <div className="ref-invited-box">
                        <span className="ref-invited">{t("frens8")}</span>
                        <span className="ref-invited-amount">{window.refs}</span>
                    </div>
                    <div className="ref-received-box">
                        <span className="ref-received-title">{t("frens5")}</span>
                        <div className="ref-resources-box">
                            {window.refs_reward.map((amount, index) => (
                                <div key={index} className="ref-resource-item">
                                    <div className="ref-resource-img">
                                        <img src={resourceImages[index + 1]} alt={`Resource ${index + 1}`} />
                                    </div>
                                    <div className="ref-resource-amount">{format(amount)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>  
                </>
            ) : null}
            <button id="referal-button" className="profile-button" onClick={() => setOpenWindow(true)}>
                <img src={ReferalIcon} alt="Referal system" />
            </button>
        </> 
    );
};

export default Referal;