import React, { useState, useEffect, useRef } from 'react';
import "./get.scss"
import ReactDOM from 'react-dom';

import { addDefault } from '../requests';
import { useTranslate } from '../translate/translate';
import config from '../port/ships/ships_upgrade.json';
import capacityImage from '../port/ships/cont-icon.png'
import Alert from '../alerts';

import PioneerImage from '../port/ships/pioneer.png';
import ExplorerImage from '../port/ships/explorer.png';
import VoyagerImage from '../port/ships/voyager.png';
import OdysseyImage from '../port/ships/odyssey.png';
import TitanImage from '../port/ships/titan.png';

import ContBlueImage from '../port/cont/blue.png';
import ContRedImage from '../port/cont/red.png';
import ContGreenImage from '../port/cont/green.png';
import ContBronzeImage from '../port/cont/bronze.png';
import ContSilverImage from '../port/cont/silver.png';
import ContGoldImage from '../port/cont/gold.png';

const containerImages = {
    1: ContBlueImage,
    2: ContRedImage,
    3: ContGreenImage,
    4: ContBronzeImage,
    5: ContSilverImage,
    6: ContGoldImage
};

const shipImages = {
    'Pioneer': PioneerImage,
    'Explorer': ExplorerImage,
    'Voyager': VoyagerImage,
    'Odyssey': OdysseyImage,
    'Titan': TitanImage
};

const getShipCapacity = (shipType, level) => {
    const shipConfig = config.ships.find(ship => ship.type === shipType);
    if (shipConfig) {
        const levelConfig = shipConfig.levels.find(lvl => lvl.level === level);
        return levelConfig ? levelConfig.capacity : 'N/A';
    }
    return 'N/A';
};

const DefaultGetWindow = ({ type, setDefaultWindowShip, setDefaultWindowConts }) => {
    const t = useTranslate();
    const [alert, setAlert] = useState(null);
    const [showPyro, setShowPyro] = useState(false);
    const [buttonText, setButtonText] = useState(t("receive"));
    const [loading, setLoading] = useState(false);
    const [prize, setPrize] = useState(null);

    useEffect(() => {setButtonText(t("receive"))}, [t]);

    const get = () => {
        setLoading(true);

        setTimeout(() => {
            if (type === 'ship' ) {
                addDefault(window.tgID, 'ship').then((res) => {
                    if (res === 'already' || res === 'error') {
                        setAlert('server-error');
                        setLoading(false)
                    } else {
                        setPrize(res);
                        window.default_ship = 1;
                        setShowPyro(true);
                        setButtonText(t("take"));
                        setLoading(false)
                    }
                });
            } else {
                addDefault(window.tgID, 'cont').then((res) => {
                    if (res === 'already' || res === 'error') {
                        setAlert('server-error');
                        setLoading(false)
                    } else {
                        setPrize(res + 1);
                        window.default_conts[res] += 1;
                        setShowPyro(true);
                        setButtonText(t("take"));
                        setLoading(false)
                    }
                });
            }
        }, 1000);
    };

    const take = () => {
        if (type === 'ship') {
            setDefaultWindowShip(false);
        } else if (type === 'conts') {
            setDefaultWindowConts(false);
        }
    };

    return (
        <>
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null}

            {ReactDOM.createPortal(
                <div className="default-get-overlay">
                    <div className="default-get">
                        <span className="default-get-title">
                            {t("free")} {type === 'ship' ? t("ship") : t("cont")}!
                        </span>

                        {showPyro && (
                            <div className="pyro">
                                <div className="before"></div>
                                <div className="after"></div>
                            </div>
                        )}

                        <div className="container">
                            {prize ? (
                                <>
                                    {type === 'ship' ? (
                                        <>
                                            <div className="default-get-ship-name">{prize}</div>
                                            <div className="default-get-ship-capacity">
                                                {getShipCapacity(prize, 1)}
                                                <img src={capacityImage} alt="Capacity" />
                                            </div>
                                            <img src={shipImages[prize]} alt={prize} />
                                        </>
                                    ) : (
                                        <img src={containerImages[prize]} alt={prize} />
                                    )}
                                </>
                            ) : (
                                <span className="question-mark">?</span>
                            )}
                        </div>

                        <button
                            className="default-get-button"
                            onClick={buttonText === t("take") ? take : get}
                            disabled={loading}
                        >
                            {!loading ? 
                                buttonText
                                :
                                <div className="loading-animation">
                                    <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </div>
                            }
                        </button>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default DefaultGetWindow;
