
import React, { useEffect, useState } from 'react';
import './profile.scss';
import { changeWallet } from '../requests'
import MiniGame from './mini-game';
import TaskList from './task-list';
import Referal from './referal';
import WalletIcon from './wallet.png';
import TutorialIcon from '../tutorial/man.png';
import RatingIcon from './rating.png';
import ClanIcon from '../navigation/clans.png';
import SettingsIcon from './settings.png';
import Settings from './settings';
import TasksIcon from './tasks.png';
import { useTranslation } from 'react-i18next';
import task_list from './task_list.json';

import { useTranslate } from '../translate/translate';
import { useTonAddress } from '@tonconnect/ui-react';
import { useTonConnectUI } from '@tonconnect/ui-react';

import Sold from '../market/sold';
import Rating from './rating';
import Warning from '../warnings';
import Alert from '../alerts';
import Clans from './clans';

import Ava1 from './ava/1_full.png';
import Ava2 from './ava/2_full.png';
import Ava3 from './ava/3_full.png';
import Ava4 from './ava/4_full.png';
import Ava5 from './ava/5_full.png';
import Ava6 from './ava/6_full.png';

import Fon from './fon2.png';

const ModalWallet = ({ isOpen, onClose, onDisconnect, WalletFormat, walletFr }) => {
    const t = useTranslate();
    if (!isOpen) return null;
  
    return (
        <div className="modal-wallet-overlay">
            <div className="modal-wallet-content">
                <span className="modal-wallet-title">{t("wallet")}</span>
                <span className="modal-wallet-info">
                    {t("wallet-info")}
                </span>
                <span className="modal-wallet-wallet">{WalletFormat(walletFr)}</span>
                <div className="modal-wallet-buttons">
                    <button onClick={onDisconnect}>{t("disconnect")}</button>
                    <button onClick={onClose}>{t("cancel2")}</button>
                </div>
            </div>
        </div>
    );
  };

const Profile = ({ setStep, setTutorial, setLVL, setXP, flotilias, setFlotilias, serverTime }) => {
    const t = useTranslate();

    const { i18n } = useTranslation();

    const [tonConnectUI] = useTonConnectUI();
    const [isConnected, setIsConnected] = useState(false);
    const wallet = useTonAddress(false);
    const walletFr = useTonAddress(true);
    const [isModalWalletOpen, setIsModalWalletOpen] = useState(false);
    const [isTutorial, setIsTutorial] = useState(false);
    const [isRating, setIsRating] = useState(false);
    const [settings, setSettings] = useState(false);
    const [modalTasks, setModalTasks] = useState(false);
    const [warning, setWarning] = useState(false);
    const [alert, setAlert] = useState(null);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [amountTasks, setAmountTasks] = useState(null);

    useEffect(() => {
        const fetchLanguage = async () => {
            i18n.changeLanguage(window.lang);
        };
    
        fetchLanguage();
    }, []);

    const WalletFormat = (Address) => {
        const formattedAddress = `${Address.slice(0, 4)}...${Address.slice(-4)}`;
        return formattedAddress;
    };  

    useEffect(() => {
        const checkWalletConnection = async () => {
          const isConnected = tonConnectUI.connected;
          if (isConnected) {
            setIsConnected(true); // Кошелек подключен
            if (window.wallet !== wallet) {
                const disconnectInfo = await changeWallet(window.tgID, wallet); // Присваивается window.wallet
                if (disconnectInfo === 'True') {
                    await tonConnectUI.disconnect();
                    setIsConnected(false);
                    window.wallet = undefined;
                    setWarning(true);
                } else if (disconnectInfo === 'error') {
                    setAlert('server-error');
                }
            }
          } else {
            setIsConnected(false); // Кошелек не подключен
          }
        };
    
        checkWalletConnection();
    }, [tonConnectUI.connected]);

    const handleConnect = async () => {
        if (isConnected) {
            setIsModalWalletOpen(true);
        } else {
            await tonConnectUI.openModal();
        }
    };

    const handleDisconnect = async () => {
        await tonConnectUI.disconnect();
        setIsConnected(false);
        setIsModalWalletOpen(false);
        window.wallet = undefined;
    };

    const startTutorialFromProfile = () => {
        setTutorial(true);
        setStep(3);
        setIsTutorial(false);
    };

    const avatar = window.avatar;
    const avatarImage = avatar === 1 ? Ava1 :
                        avatar === 2 ? Ava2 :
                        avatar === 3 ? Ava3 :
                        avatar === 4 ? Ava4 :
                        avatar === 5 ? Ava5 :
                        avatar === 6 ? Ava6 : Ava1;
    
    // Количество невыполненных заданий
    useEffect(() => {
        const fetchData = async () => {
            if (window.tgID && !modalTasks) {
                //const getTasksRes = await getTasks(window.tgID);
                setCompletedTasks(window.tasks);

                // Фильтруем активные задания
                const activeTasks = task_list.task_list.filter(task => task.active);

                // Проверяем, какие активные задания не выполнены
                const uncompletedTasks = activeTasks.filter(task => 
                    !window.tasks.includes(task.id)
                );

                // Подсчитываем количество невыполненных заданий
                setAmountTasks(uncompletedTasks.length);
            }
        };

        fetchData();
    }, [modalTasks]);

    return (
        <>

        <ModalWallet
            isOpen={isModalWalletOpen}
            onClose={() => setIsModalWalletOpen(false)}
            onDisconnect={handleDisconnect}
            walletFr={walletFr}
            WalletFormat={WalletFormat}
        />

        {alert ? <Alert alert={alert} setAlert={setAlert}/> : null}

        {settings && <Settings settings={settings} setSettings={setSettings} setAlert={setAlert}/>}

        <div className="home">
            {warning ? <Warning warning={warning} setWarning={setWarning}/> : null}
            <Sold />

            {flotilias && <Clans flotilias={flotilias} setFlotilias={setFlotilias} />}
            
            {modalTasks && <TaskList modalTasks={modalTasks} setModalTasks={setModalTasks} completedTasks={completedTasks} setCompletedTasks={setCompletedTasks}/>}

            <div id="clans-portal"></div>

            <img className="fon" src={Fon} alt="" />
            <img className="ava" src={avatarImage} alt="Captain" />

            <div className="top-buttons">
                <div className='ref-tut-buttons'>
                    <Referal />
                    <button className='profile-tutorial-button' onClick={() => setIsTutorial(true)}>
                        <img src={TutorialIcon} alt="Tutorial" />
                    </button>
                    <button className='profile-flotilias-button' onClick={() => setFlotilias(true)}>
                        <img src={ClanIcon} alt="Flotilias" />
                    </button>
                </div>
                <div className='lang-wallet-buttons'>
                    <button className='settings-button' onClick={() => setSettings(true)}>
                        <img src={SettingsIcon} alt="Settings" />
                    </button>

                    <button className='ton-button-profile' onClick={handleConnect}>
                        <img src={WalletIcon} alt="Wallet" />
                    </button>

                    <button className='rating-button' onClick={() => setIsRating(true)}>
                        <img src={RatingIcon} alt="Rating" />
                    </button>
                </div>
            </div>

            <MiniGame  serverTime={serverTime} />

            <button className='tasks-button' onClick={() => setModalTasks(true)}>
                <img src={TasksIcon} alt="Tasks" />
                {amountTasks && 
                    <div className='amount-tasks'>
                        {amountTasks}
                    </div>
                }
                
            </button>

            {isTutorial ? (
                <div className='profile-tutorial-overlay'>
                    <div className='profile-tutorial-box'>
                        <div className='profile-tutorial-tell-box'>
                            <span>{t("prof-tutorial")}</span>
                            <button className='profile-tutorial-yes' onClick={() => startTutorialFromProfile()}>
                                {t("yes")}
                            </button>
                            <button className='profile-tutorial-no' onClick={() => setIsTutorial(false)}>
                                {t("no")}
                            </button>
                            <img className='profile-tutorial-man' src={TutorialIcon} alt="Tutorial" />
                        </div>
                    </div>
                </div>
            ) : null}

            {isRating ? (
                <Rating isRating={isRating} setIsRating={setIsRating}/>
            ) : null}

        </div>

        </>
    );
};

export default Profile;