import React, { useEffect, useState } from 'react';
import './tutorial.scss';
import { useTranslation } from 'react-i18next';
import { useTranslate } from '../translate/translate';
import { tutorialEnd } from '../requests';

import manImage from './man.png';

import LangIconEn from '../translate/en.png';
import LangIconRu from '../translate/ru.png';
import LangIconThai from '../translate/thai.png';
import arrowImage from '../navigation/arrow-down.png'

import shipImage from '../port/ship.png';
import contImage from '../port/cont.png';
import storeImage from '../port/store.png';
import rigImage from './oil-rig.png';
import voyageImage from '../navigation/trip.png';
import componentImage from '../components/3.png';
import marketImage from '../navigation/market.png';
import finalImage from './final.png';
import voyagesImage from './voyages.jpg';
import warehouseImage from './warehouse.jpg';
import warehouseEnImage from './warehouse_en.jpg';
import rigsImage from './rigs.jpg';
import rigsEnImage from './rigs_en.jpg';
import componentsImage from './components.jpg';
import componentsEnImage from './components.jpg';
import marketsImage from './market.jpg';
import marketsEnImage from './market_en.jpg';
import logoImage from '../logo.png';

import Ava1 from '../profile/ava/1.png';
import Ava2 from '../profile/ava/2.png';
import Ava3 from '../profile/ava/3.png';
import Ava4 from '../profile/ava/4.png';
import Ava5 from '../profile/ava/5.png';
import Ava6 from '../profile/ava/6.png';
import AvaSelectIcon from '../profile/ava/choose.png';

import ContBlueImage from '../port/cont/blue.png';
import ContRedImage from '../port/cont/red.png';
import ContGreenImage from '../port/cont/green.png';
import ContBronzeImage from '../port/cont/bronze.png';
import ContSilverImage from '../port/cont/silver.png';
import ContGoldImage from '../port/cont/gold.png';

import PioneerImage from '../port/ships/pioneer.png';
import ExplorerImage from '../port/ships/explorer.png';
import VoyagerImage from '../port/ships/voyager.png';
import OdysseyImage from '../port/ships/odyssey.png';
import TitanImage from '../port/ships/titan.png';

import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';
import Resource3Image from '../resources/3.png';
import Resource4Image from '../resources/4.png';
import Resource5Image from '../resources/5.png';
import Resource6Image from '../resources/6.png';
import Resource7Image from '../resources/7.png';
import Resource8Image from '../resources/8.png';
import Resource9Image from '../resources/9.png';
import Resource10Image from '../resources/10.png';
import Resource11Image from '../resources/11.png';
import Resource12Image from '../resources/12.png';
import Resource13Image from '../resources/13.png';
import Resource14Image from '../resources/14.png';

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const shipImages = {
    1: {img: PioneerImage, name: 'Pioneer'},
    2: {img: ExplorerImage, name: 'Explorer'},
    3: {img: VoyagerImage, name: 'Voyager'},
    4: {img: OdysseyImage, name: 'Odyssey'},
    5: {img: TitanImage, name: 'Titan'}
}

const contImages = {
    1: {img: ContBlueImage},
    2: {img: ContRedImage},
    3: {img: ContGreenImage},
    4: {img: ContBronzeImage},
    5: {img: ContSilverImage},
    6: {img: ContGoldImage}
}

const languageIcon = {
    'ru': LangIconRu,
    'en': LangIconEn,
    'thai': LangIconThai
};

function Tutorial({ tutorial, setTutorial, step, setStep, setServerError }) {
    const { i18n } = useTranslation();
    const t = useTranslate();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const [chooseLanguage, setChooseLanguage] = useState(null);

    const [nextStepAvailable, setNextStepAvailable] = useState(false);
    const [animation, setAnimation] = useState(true);
    const [currentShipIndex, setCurrentShipIndex] = useState(1);
    const [animationShip, setAnimationShip] = useState(true);
    const [avaChoose, setAvaChoose] = useState(0);
    const [currentContIndex, setCurrentContIndex] = useState(1);
    const [animationCont, setAnimationCont] = useState(true);
    const [tutorialRepeat, setTutorialRepeat] = useState(false);
    const [showPyro, setShowPyro] = useState(false);
    const [finalLoading, setFinalLoading] = useState(false);

    const navImg = {
        1: { img: languageIcon[currentLanguage], name: 'Language' },
        2: { img: manImage, name: 'Avatar' },
        3: { img: shipImage, name: 'Ships' },
        4: { img: contImage, name: 'Containers' },
        5: { img: voyageImage, name: 'Voyages' }, 
        6: { img: storeImage, name: 'Warehouse' }, 
        7: { img: rigImage, name: 'Oil rigs' },
        8: { img: componentImage, name: 'Components' },
        9: { img: marketImage, name: 'Market' },
        10: { img: finalImage, name: 'Final' }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setAnimation(false);
            setNextStepAvailable(false);
            setTimeout(() => {
                if (step != 1 && step != 2) {
                    setNextStepAvailable(true);
                }
            }, 300);
        }, 300);

        return () => clearTimeout(timer);
    }, [step]);

    useEffect(() => {
        if (avaChoose) {
            setNextStepAvailable(true);
        }
    }, [avaChoose]);

    useEffect(() => {
        if (step === 1 && chooseLanguage) {
            setNextStepAvailable(true);
        }
    }, []);

    useEffect(() => {
        if (window.default_ship > 0) {
            setStep(3);
            setTutorialRepeat(true);
        }
    }, []);

    const swapLanguage = (language) => {
        i18n.changeLanguage(language);
        setCurrentLanguage(language);
        setChooseLanguage(language);
        setNextStepAvailable(true);
    };

    const nextStep = async () => {
        setNextStepAvailable(false);
        setStep(prev => prev+1);
        setAnimation(true);
    };

    const prevStep = async () => {
        if (tutorialRepeat && step === 3) {
            return
        } else {
            setNextStepAvailable(false);
            setStep(prev => prev-1);
            setAnimation(true);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
          setAnimationShip(true);
          setTimeout(() => {
            setCurrentShipIndex((prevIndex) => (prevIndex % Object.keys(shipImages).length) + 1);
            setAnimationShip(false);
          }, 1000);
        }, 1500);
    
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
          setAnimationCont(true);
          setTimeout(() => {
            setCurrentContIndex((prevIndex) => (prevIndex % Object.keys(contImages).length) + 1);
            setAnimationCont(false);
          }, 1000);
        }, 1500);
    
        return () => clearInterval(interval);
    }, []);

    const finalStep = async () => {
        setFinalLoading(true);
        if (!window.tutorial) {
            window.lang = chooseLanguage;
            window.avatar = avaChoose;
            window.tutorial = true;
            const response = await tutorialEnd(window.tgID, chooseLanguage, avaChoose);
            if (response === 'error') {
                setServerError(true);
            }

            setShowPyro(true);
            setTimeout(() => {
                setFinalLoading(false);
                setShowPyro(false);
                setTutorial(false);
            }, 1500);
        } else {
            setFinalLoading(false);
            setShowPyro(false);
            setTutorial(false);
        }
    };

    return (
        <div className='tutorial-overlay'>
            <div className='tutorial-content-box'>
                <div className={`tutorial-content ${animation ? '' : 'visible'}`}>
                    {step === 1 ? (
                        <>
                        <div className="language-box">
                                <span className="language-title">{t('language')}</span>

                                <div className="overlay">
                                    <button className={`lang-button ${chooseLanguage === 'en' ? 'selected' : ''}`} onClick={() => swapLanguage('en')}>
                                        <img src={LangIconEn} alt="English" />
                                        <span>English</span>
                                    </button>
                                    <button className={`lang-button ${chooseLanguage === 'ru' ? 'selected' : ''}`} onClick={() => swapLanguage('ru')}>
                                        <img src={LangIconRu} alt="Русский" />
                                        <span>Русский</span>
                                    </button>
                                    <button className={`lang-button ${chooseLanguage === 'thai' ? 'selected' : ''}`} onClick={() => swapLanguage('thai')}>
                                        <img src={LangIconThai} alt="แบบไทย" />
                                        <span>แบบไทย</span>
                                    </button>
                                </div>
                            </div>
                            
                        </>
                    ) : step === 2 ? (
                        <>
                            <span className='text-bold'>{t('tutorial7')}</span>
                            <div className='ava-box'>
                                {[Ava1, Ava2, Ava3, Ava4, Ava5, Ava6].map((ava, index) => (
                                    <button key={index} className={`ava-button ${avaChoose === index + 1 ? 'selected' : ''}`} onClick={() => setAvaChoose(index + 1)}>
                                        <img src={ava} alt={`Avatar ${index + 1}`} />
                                        {avaChoose === index + 1 && (
                                            <img className='choose-icon' src={AvaSelectIcon} alt='Selected'/>
                                        )}
                                    </button>
                                ))}
                            </div>
                        </>
                    ) : step === 3 ? (
                        <>
                            <div className='ship-box'>
                                <img
                                    src={shipImages[currentShipIndex].img}
                                    alt={shipImages[currentShipIndex].name}
                                    className={`ship-image ${animationShip ? '' : 'visible'}`}
                                />
                                <div className={`name ${animationShip ? '' : 'visible'}`}>{shipImages[currentShipIndex].name}</div>
                            </div>
                            <span className='text-bold'>{t('tutor1')}</span>
                            <span className='text-bold'>{t('tutor2')}</span>
                            <span className='text-bold'>{t('tutor3')}</span>
                        </>
                    ) : step === 4 ? (
                        <>
                            <div className='cont-box'>
                                <img
                                    src={contImages[currentContIndex].img}
                                    alt='Container'
                                    className={`cont-image ${animationCont ? '' : 'visible'}`}
                                />
                            </div>
                            <span className='text-bold'>{t('tutor4')}</span>
                            <span className='text-bold'>{t('tutor5')}</span>
                            <span className='text-bold'>{t('tutor6')}</span>
                        </>
                    ) : step === 5 ? (
                        <>
                            <img className='voyages-img' src={voyagesImage} alt="Voyages" />
                            <span className='text-bold'>{t('tutor7')}</span>
                            <div className='resources-box'>
                                {Object.keys(resourceImages).map((key) => (
                                    <img key={key} src={resourceImages[key]} alt={`Resource ${key}`} />
                                ))}
                            </div>
                            <span className='text-bold'>{t('tutor8')}</span>
                        </>
                    ) : step === 6 ? (
                        <>
                            <img className='warehouse-img' src={window.lang == 'en' || window.lang == 'thai' ? warehouseEnImage : warehouseImage} alt="Warehouse" />
                            <span className='text-bold'>{t('tutor9')}</span>
                            <span className='text-bold'>{t('tutor10')}</span>
                        </>
                    ) : step === 7 ? (
                        <>
                            <img className='rig-img' src={window.lang == 'en' || window.lang == 'thai' ? rigsEnImage : rigsImage} alt="Oil rig" />
                            <span className='text-bold'>{t('tutor11')}</span>
                            <span className='text-bold'>{t('tutor12')}</span>
                        </>
                    ) : step === 8 ? (
                        <>
                            <img className='components-img' src={window.lang == 'en' || window.lang == 'thai' ? componentsEnImage : componentsImage} alt="Workshop" />
                            <span className='text-bold'>{t('tutor13')}</span>
                            <span className='text-bold'>{t('tutor14')}</span>
                        </>
                    ) : step === 9 ? (
                        <>
                            <img className='market-img' src={window.lang == 'en' || window.lang == 'thai' ? marketsEnImage : marketsImage} alt="Market" />
                            <span className='text-bold'>{t('tutor15')}</span>
                            <span className='text-bold'>{t('tutor16')}</span>
                        </>
                    ) : step === 10 ? (
                        <>
                            <img className='logo-img' src={logoImage} alt="Container Rush" />
                            <span className='text-bold'>{t('tutor17')}</span>
                            <span className='text-bold'>{t("tutorial45")}</span>
                            <span className='text-bold'>{t("tutorial46")}</span>
                            <span className='text-bold'>{t("tutorial47")}</span>
                            <button className={`final-button ${finalLoading && 'disabled'}`} onClick={() => finalStep()}>
                                {!finalLoading ? t('final-tutorial') :
                                    <div className="loading-animation">
                                        <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                        </svg>
                                    </div>
                                }
                            </button>
                        </>
                    ) : null}

                    {showPyro && (
                        <div className="pyro">
                            <div className="before"></div>
                            <div className="after"></div>
                        </div>
                    )}
                </div> 
            </div>

            <div className='tutorial-nav'>
                <hr className='line'/>

                {step != 1 &&
                    <button className='left' onClick={() => prevStep()}>
                        <img src={navImg[step-1].img} alt="Step" />
                        <span>
                            {t(navImg[step-1].name)}
                        </span>
                    </button>
                }

                <button className='center'>
                    <img src={navImg[step].img} alt="Step" />
                    <span>
                        {t(navImg[step].name)}
                    </span>
                </button>

                {nextStepAvailable && step != 10 &&
                    <button className={`right ${animation ? '' : 'visible'}`} onClick={() => nextStep()}>
                        <img src={navImg[step+1].img} alt="Step" />
                        <span>
                            {t(navImg[step+1].name)}
                        </span>
                    </button>
                }

            </div>
            
        </div>
    );
}

export default Tutorial;
