import React, { useState, useEffect, useRef } from 'react';
import "./upgrade.scss";
import ReactDOM from 'react-dom';
import { changeRigLvl } from '../../requests';
import { format, formatCapacity } from '../../format-number';
import { useTranslate } from '../../translate/translate';
import UpgradeImage from '../cont/upgrade_yes.png';


const UpgradeRig = ({ modalUpgrade, setModalUpgrade, resourceImages, componentImages, setAlert, 
    setShowPyro, userResources, userComponents, rigs_config, CapacityImage, SpeedImage, rigs, fetchData,
    currentFill, setUserResources, setUserComponents }) => {

    const t = useTranslate();
    const [loading, setLoading] = useState(false);


    let currentLevel;
    let nextLevel;
    if (modalUpgrade.param === 'capacity') {
        currentLevel = rigs_config.rigs[modalUpgrade.rig].capacity.find(level => level.level === rigs[modalUpgrade.rig].lvl_capacity);
        nextLevel = rigs_config.rigs[modalUpgrade.rig].capacity.find(level => level.level === rigs[modalUpgrade.rig].lvl_capacity+1);
    } else if (modalUpgrade.param === 'speed') {
        currentLevel = rigs_config.rigs[modalUpgrade.rig].speed.find(level => level.level === rigs[modalUpgrade.rig].lvl_speed);
        nextLevel = rigs_config.rigs[modalUpgrade.rig].speed.find(level => level.level === rigs[modalUpgrade.rig].lvl_speed+1);
    }

    const upgradeRig = () => {
        setLoading(true);
        if (currentLevel.level != 10) {
            const moneyCost = Array(14).fill(0);
    
            const resourceItem = nextLevel.requirements.find(item => item.resourceId !== undefined);
            if (resourceItem) {
                moneyCost[0] = resourceItem.amount;
            }
    
            const componentsCost = Array(21).fill(0);
            nextLevel.requirements.forEach(item => {
                if (item.componentId !== undefined) {
                    componentsCost[item.componentId - 1] = item.amount;
                }
            });
    
            const isMoney = moneyCost.every((amount, index) => amount <= 0 || amount <= userResources[index]);
            const isComponents = componentsCost.every((amount, index) => amount <= 0 || amount <= userComponents[index]);
    
            if (isMoney) {
                if (isComponents) {
                    changeRigLvl(window.tgID, modalUpgrade.rig, modalUpgrade.param)
                        .then((response) => {

                            if (response === 'error') {
                                setAlert('server-error');
                            } else if (response === 'insufficiently') {
                                setAlert('insufficiently');
                            } else if (response === 'True') {

                                if (modalUpgrade.param === 'capacity') {
                                    fetchData();
                                    setAlert('rig-capacity-lvlup');
                                } else if (modalUpgrade.param === 'speed') {
                                    fetchData();
                                    setAlert('rig-speed-lvlup');
                                }

                                setUserResources(window.resources.map((value, index) => value - window.resources_market[index]));
                                setUserComponents(window.components.map((value, index) => value - window.components_market[index]));

                                setModalUpgrade(null);
                                setShowPyro(prev => ({ ...prev, [modalUpgrade.rig]: true }));
                                setTimeout(() => setShowPyro(prev => ({ ...prev, [modalUpgrade.rig]: false })), 1000);
                            }
                        })
                        .catch(() => {
                            setAlert('error');
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    setAlert('not-components-upgrade');
                    setLoading(false);
                }
            } else {
                setAlert('not-money-upgrade');
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    return (
        <>      
            {modalUpgrade ? (
                ReactDOM.createPortal(
                    <div className="upgrade-rig-overlay" onClick={() => setModalUpgrade(null)}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="lvls">
                                <div className="lvl-box">
                                    <div className="header-lvl">
                                        <span>{t('current')}</span>
                                        <div className="lvl">{t('lvl')} {modalUpgrade.param === 'capacity' ? rigs[modalUpgrade.rig].lvl_capacity :
                                            rigs[modalUpgrade.rig].lvl_speed}</div>
                                    </div>

                                    <div className="parameter">
                                        <div className="img-box">
                                            {modalUpgrade.param === 'capacity' ? <img src={CapacityImage} alt="Capacity" /> :
                                            <img src={SpeedImage} alt="Speed" />}
                                        </div>
                                        <div className="amount-box">
                                            {modalUpgrade.param === 'capacity' ? currentLevel.capacity : currentLevel.speed}
                                            <img src={resourceImages[2]} alt="Fuel" />
                                        </div>
                                    </div>
                                </div>

                                <div className="lvl-box">
                                    <div className="header-lvl">
                                        <span>{t('next-info')}</span>
                                        <div className="lvl">{t('lvl')} {modalUpgrade.param === 'capacity' ? rigs[modalUpgrade.rig].lvl_capacity+1 :
                                            rigs[modalUpgrade.rig].lvl_speed+1}</div>
                                    </div>

                                    <div className="parameter">
                                        <div className="img-box">
                                            {modalUpgrade.param === 'capacity' ? <img src={CapacityImage} alt="Capacity" /> :
                                            <img src={SpeedImage} alt="Speed" />}
                                        </div>
                                        <div className="amount-box">
                                            {modalUpgrade.param === 'capacity' ? nextLevel.capacity : nextLevel.speed}
                                            <img src={resourceImages[2]} alt="Fuel" />
                                            {modalUpgrade.param === 'speed' && <span>/ч</span>}
                                        </div>
                                    </div>
                                </div>

                                <img className='arrow' src={UpgradeImage} alt="Upgrade" />
                            </div>

                            <div className='upgrade-box'>
                                <div className="elements">
                                    {nextLevel.requirements.map((req, index) => (
                                        <div key={index} className="element">
                                            <div className="image">
                                                {req.resourceId ? 
                                                    <img src={resourceImages[req.resourceId]} alt={`Resource ${req.resourceId}`} /> :
                                                    <img src={componentImages[req.componentId]} alt={`Component ${req.componentId}`} />
                                                }
                                            </div>
                                            {req.resourceId ? <span>{format(req.amount)}</span> : <span>{formatCapacity(req.amount)}</span> }
                                        </div>
                                    ))}
                                </div>

                                <button className={`upgrade-button ${(loading) ? 'disabled' : ''}`}  onClick={() => upgradeRig()} >
                                    {loading ? (
                                        <div className="loading-animation">
                                            <svg className="spinner" strokeWidth="65px" height="70%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                            </svg>
                                        </div>
                                    ) : 
                                        <span>{t('upgrade')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default UpgradeRig;