
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './rent.scss';
import { useTranslate } from '../../translate/translate';
import BackImage from '../back.png';
import capacityImage from '../ships/cont-icon.png'
import tonImage from '../../market/ton.png'
import config from '../ships/ships_upgrade.json';

import Rented from './rented';
import Warning from '../../warnings';
import Alert from '../../alerts';

import { toUserFriendlyAddress } from '@tonconnect/sdk';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { beginCell } from '@ton/core';
import { getRentShips, getViewsShip, rentShip, changeWallet, setViewsShip } from '../../requests'

import PioneerImage from '../ships/pioneer.png';
import ExplorerImage from '../ships/explorer.png';
import VoyagerImage from '../ships/voyager.png';
import OdysseyImage from '../ships/odyssey.png';
import TitanImage from '../ships/titan.png';

const shipImages = {
    'Pioneer': PioneerImage,
    'Explorer': ExplorerImage,
    'Voyager': VoyagerImage,
    'Odyssey': OdysseyImage,
    'Titan': TitanImage
};

const ships = Object.keys(shipImages);

const ModalRent = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    return ReactDOM.createPortal(
      <div className="rent-overlay" onClick={onClose}>
        {children}
      </div>,
      document.body
    );
  };

const Rent = ({ setActiveSection, serverTime }) => {
    const t = useTranslate();
    const [tonConnectUI] = useTonConnectUI();
    const [currentShipType, setCurrentShipType] = useState(ships[0]);
    const [dataShips, setDataShips] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [rentingShipId, setRentingShipId] = useState(null);
    const [loadingRent, setLoadingRent] = useState(false);

    const [isConnected, setIsConnected] = useState(false);
    const wallet = useTonAddress(false);
    const [warning, setWarning] = useState(false);
    const [alert, setAlert] = useState(false);

    const fetchShipDetails = async () => {
        const shipsRes = await getRentShips();
        setDataShips(shipsRes);
    };

    useEffect(() => {
        fetchShipDetails();
    }, []);

    const nextShip = () => {
        const currentIndex = ships.indexOf(currentShipType);
        const nextIndex = (currentIndex + 1) % ships.length;
        setCurrentShipType(ships[nextIndex]);
    };

    const prevShip = () => {
        const currentIndex = ships.indexOf(currentShipType);
        const prevIndex = (currentIndex - 1 + ships.length) % ships.length;
        setCurrentShipType(ships[prevIndex]);
    };

    const WalletAddress = (rawAddress) => {
        const userFriendlyAddress = toUserFriendlyAddress(rawAddress);
        const formattedAddress = `${userFriendlyAddress.slice(0, 4)}...${userFriendlyAddress.slice(-4)}`;
        return formattedAddress;
    };  

    const getShipCapacity = (shipType, level) => {
        const shipConfig = config.ships.find(ship => ship.type === shipType);
        if (shipConfig) {
            const levelConfig = shipConfig.levels.find(lvl => lvl.level === level);
            return levelConfig ? levelConfig.capacity : 'N/A';
        }
        return '';
    };

    function generateID() {
        const randomNumber = Math.floor(100000000000 + Math.random() * 900000000000);
        return randomNumber;
    }

    const rentLotShip = async (ship) => {
        const transactionID = generateID();
    
        const body = beginCell()
            .storeUint(0, 32)
            .storeStringTail(`Container Rush: Rent ${ship.name} (${transactionID})`)
            .endCell();
    
        const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 30, // 30 sec
            messages: [
                {
                    address: ship.wallet_owner,
                    amount: ship.price_rent * 10**9,
                    payload: body.toBoc().toString("base64")
                }
            ]
        };

        if (transactionID) {
            const response = await rentShip(ship.name, window.tgID, transactionID);

            if (response === 'error' || response === 'Ship not exist' || response === 'Ship already rented') {
                const responseViews = await setViewsShip(ship.name);

                if (responseViews == 'error' || responseViews == 'Already') {
                    setAlert('server-error');
                }
                
            } else if (response === 'Success') {
                try {
                    await tonConnectUI.sendTransaction(transaction);
                    
                    // Фильтруем массив, удаляя запись с указанным name
                    const updatedShips = dataShips[ship.type].filter((ship) => ship.name !== ship.name);

                    // Если массив стал пустым, удаляем ключ целиком
                    if (updatedShips.length === 0) {
                        const updatedDataShips = { ...dataShips };
                        delete updatedDataShips[ship.type];
                        setDataShips(updatedDataShips);
                    } else {
                        // Обновляем состояние только для указанного типа
                        setDataShips((prevState) => ({
                            ...prevState,
                            [ship.type]: updatedShips,
                        }));
                    }

                    setAlert('rent-success');
                } catch { // Транзакция отменена
                    await setViewsShip(ship.name);
                    setAlert('rent-close');
                }
            }

            setLoadingRent(false);
            setRentingShipId(null);
            setModalOpen(false);
        }
    };

    console.log(dataShips);

    useEffect(() => {
        const checkWalletConnection = async () => {
          const isConnected = tonConnectUI.connected;
          if (isConnected) {
            setIsConnected(true); // Кошелек подключен
            
            if (window.wallet !== wallet) {
                const disconnectInfo = await changeWallet(window.tgID, wallet); // Присваивается window.wallet
                if (disconnectInfo === 'True') {
                    await tonConnectUI.disconnect();
                    setIsConnected(false);
                    window.wallet = undefined;
                    setWarning(true);
                } else if (disconnectInfo === 'error') {
                    setAlert('server-error');
                }
            }
          } else {
            setIsConnected(false); // Кошелек не подключен
          }
        };
    
        checkWalletConnection();
    }, [tonConnectUI.connected]);

    const handleRent = async (ship) => {
        setRentingShipId(ship.id);

        if (window.wallet) {
            try {
                const viewsStatus = ship.views || ship.rented || new Date(ship.rent).getTime() > serverTime;

                const close = () => {
                    setRentingShipId(null);
                    setLoadingRent(false);
                    setModalOpen(false);
                }

                if (viewsStatus) {
                    setAlert('not-cancel-rent');
                    close();
                    return;
                } else {
                    setModalOpen(true);
                    setModalContent(
                        <div className="rent">
                            <div className="rent-message">
                                <div className="rent-message">
                                    <div className="rent-ship">
                                        <img src={shipImages[ship.type]} alt={ship.type} />
                                        <div className="rent-ship-name">{ship.name}</div>
                                        <div className="rent-ship-lvl">{t("lvl")} {ship.lvl}</div>
                                        <div className="rent-ship-capacity">
                                            {getShipCapacity(ship.type, ship.lvl)}
                                            <img src={capacityImage} alt="Capacity" />
                                        </div>
                                    </div>
                                    <span className="rent-message-answer">
                                        {t('rent3')} {t('for')} {ship.price_rent} TON?
                                    </span>
                                </div>
                            
                                <div className="rent-buttons">
                                    <button
                                        className={`rent-button-yes ${loadingRent ? 'disabled' : ''}`}
                                        disabled={loadingRent}
                                        onClick={() => rentLotShip(ship)}
                                    >
                                        {!loadingRent ? t('yes') : (
                                            <div className="loading-animation">
                                                <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                    <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                                </svg>
                                            </div>
                                        )}
                                    </button>
    
                                    <button className="rent-button-no" onClick={() => close()}>
                                        {t('no')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                }   
            } catch (error) {
                setAlert('server-error');
            }
        } else { await tonConnectUI.openModal(); }
    };

    return (
        <>
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null} 
            {warning ? <Warning warning={warning} setWarning={setWarning}/> : null}
            <Rented />

            <ModalRent isOpen={modalOpen} loadingRent={loadingRent} setLoadingRent={setLoadingRent}>
                {modalContent}
            </ModalRent>

            <div className="rent-page">
                <div className="rent-header">
                    <button className="rent-back-button" onClick={() => setActiveSection('port')}>
                        <img src={BackImage} alt="Back" />
                    </button>
                    <span className="rent-title">{t('title-rent')}</span>
                </div>
                
                <div className="rent-slider-box">
                    <div className="rent-slider">
                        <button className="rent-ship-item" onClick={prevShip} style={{ transform: 'scale(0.8)', opacity: 0.8 }}>
                            <img src={shipImages[ships[(ships.indexOf(currentShipType) - 1 + ships.length) % ships.length]]} alt="Previous Ship" />
                            <div className="rent-ship-slider-name">{ships[(ships.indexOf(currentShipType) - 1 + ships.length) % ships.length]}</div>
                        </button>
                        <button className="rent-ship-item">
                            <img src={shipImages[currentShipType]} alt="Current Ship" />
                            <div className="rent-ship-slider-name">{currentShipType}</div>
                        </button>
                        <button className="rent-ship-item" onClick={nextShip} style={{ transform: 'scale(0.8)', opacity: 0.8 }}>
                            <img src={shipImages[ships[(ships.indexOf(currentShipType) + 1) % ships.length]]} alt="Next Ship" />
                            <div className="rent-ship-slider-name">{ships[(ships.indexOf(currentShipType) + 1) % ships.length]}</div>
                        </button>
                    </div>
                </div>

                <div className="rent-info">
                    <span>{t('rent4')}</span>
                    <span>{t('rent5')}</span>
                    <span><b>{t('rent6')}</b></span>
                </div>

                {dataShips ? (
                    dataShips[currentShipType] && dataShips[currentShipType].length > 0 ? (
                        <div className="rent-lots-box">
                            <div className="rent-lot-box">
                                {dataShips[currentShipType].map(ship => {
                                    const isOwnLot = ship.wallet_owner === window.wallet;
                                    return (
                                        <div className="rent-lot" key={ship.id}>
                                            <div className="rent-lot-owner">
                                                <span className="rent-lot-owner-title">{t('owner')}</span>
                                                <span className="rent-lot-owner-wallet">{WalletAddress(ship.wallet_owner)}</span>
                                                </div>
                                            <div className="rent-lot-lvl">{t("lvl")} {ship.lvl}</div>
                                            <div className="rent-lot-capacity">
                                                {getShipCapacity(ship.type, ship.lvl)}
                                                <img src={capacityImage} alt="Capacity" />
                                            </div>
                                            <button
                                                className={`rent-lot-buy ${isOwnLot || rentingShipId === ship.id ? 'disabled' : ''}`}
                                                disabled={isOwnLot || rentingShipId === ship.id}
                                                onClick={() => handleRent(ship)}
                                            >

                                                {rentingShipId !== ship.id ? (
                                                    <>
                                                    <img src={tonImage} alt="Price" />
                                                    <div className="rent-lot-buy-price">
                                                        <span>{t('rent')}</span>
                                                        <span>{ship.price_rent}</span>
                                                    </div>
                                                    </>
                                                ) : (
                                                    <div className="loading-animation-renting">
                                                        <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                                        </svg>
                                                    </div>
                                                )}
                                                
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="rent-lots-box">
                            <div className="rent-no-lots">{t('rent7')}</div>
                        </div>
                    )
                ) : (
                    <div className="rent-lots-box">
                        <div className="rent-loading-animation">
                            <svg className="spinner" strokeWidth="65px" height="40%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Rent;