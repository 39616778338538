
import React, { useState, useEffect } from 'react';
import './workshop.scss';
import { useTranslate } from '../../translate/translate';


import BackImage from '../back.png';

import { refreshItems, createComponent } from '../../requests'
import { format, formatCapacity } from '../../format-number';
import config from '../../components/components.json';
import config_store from '../store/config_store.json';
import Alert from '../../alerts';
import InfoWorkshop from './info';

import plus from '../../plus.png';
import minus from '../../minus.png';

import WorkshopImage from './workshop.jpeg';
import WorkshopLines2 from './workshop_lines2.png'
import WorkshopLines3 from './workshop_lines3.png'
import WorkshopLines4 from './workshop_lines4.png'

import Resource1Image from '../../resources/1.png';
import Resource3Image from '../../resources/3.png';
import Resource4Image from '../../resources/4.png';
import Resource5Image from '../../resources/5.png';
import Resource6Image from '../../resources/6.png';
import Resource7Image from '../../resources/7.png';
import Resource8Image from '../../resources/8.png';
import Resource9Image from '../../resources/9.png';
import Resource10Image from '../../resources/10.png';
import Resource11Image from '../../resources/11.png';
import Resource12Image from '../../resources/12.png';
import Resource13Image from '../../resources/13.png';
import Resource14Image from '../../resources/14.png';

import Component1Image from '../../components/1.png';
import Component2Image from '../../components/2.png';
import Component3Image from '../../components/3.png';
import Component4Image from '../../components/4.png';
import Component5Image from '../../components/5.png';
import Component6Image from '../../components/6.png';
import Component7Image from '../../components/7.png';
import Component8Image from '../../components/8.png';
import Component9Image from '../../components/9.png';
import Component10Image from '../../components/10.png';
import Component11Image from '../../components/11.png';
import Component12Image from '../../components/12.png';
import Component13Image from '../../components/13.png';
import Component14Image from '../../components/14.png';
import Component15Image from '../../components/15.png';
import Component16Image from '../../components/16.png';
import Component17Image from '../../components/17.png';
import Component18Image from '../../components/18.png';
import Component19Image from '../../components/19.png';
import Component20Image from '../../components/20.png';
import Component21Image from '../../components/21.png';

const resourceImages = {
    1: Resource1Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const Workshop = ({ setActiveSection }) => {
    const t = useTranslate();
    const [userResources, setUserResources] = useState(window.resources);
    const [userComponents, setUserComponents] = useState(window.components);
    const [selectComponent, setSelectComponent] = useState();
    const [amountComponent, setAmountComponent] = useState(1);
    const [isRecipeValid, setIsRecipeValid] = useState(false);
    const [capacityResources, setCapacityResources] = useState([]);
    const [capacityComponents, setCapacityComponents] = useState([]);
    const [info, setInfo] = useState(false);
    const [showPyro, setShowPyro] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(false);

    const refreshData = async () => {
        await refreshItems(window.tgID);
        setUserResources(window.resources.map((value, index) => value - window.resources_market[index]));
        setUserComponents(window.components.map((value, index) => value - window.components_market[index]));

        const storeLevel = config_store.store.find(store => store.lvl == window.lvl_store);
        if (storeLevel) {
            setCapacityResources(storeLevel.capacity[0].resources);
            setCapacityComponents(storeLevel.capacity[0].components);
        }
    };

    useEffect(() => {
        refreshData();
    }, []);

    const getComponentById = (id) => {
        const component = config.components.find(comp => comp.id == id);
        return component ? component : null;
    };

    const changeAmountComponent = (action) => {
        if (action === 'plus') {
            setAmountComponent(amountComponent + 1);
        } else if (action === 'minus') {
            if (amountComponent != 1) {
                setAmountComponent(amountComponent - 1);
            }
        }
    };

    const calcEnough = (element) => {
        if (element.resourceId) { return userResources[element.resourceId - 1] >= element.amount*amountComponent } 
        else if (element.componentId) { return userComponents[element.componentId - 1] >= element.amount*amountComponent }
    };

    useEffect(() => {
        if (selectComponent) {
            const allValid = getComponentById(selectComponent).resources.every(calcEnough);
            setIsRecipeValid(allValid);
        } else {
            setIsRecipeValid(false);
        }
    }, [selectComponent, amountComponent, userComponents, amountComponent]);

    const createComponentFunc = () => {
        setLoading(true);
        if (selectComponent && isRecipeValid) {
            if (userResources[0] >= getComponentById(selectComponent).money * amountComponent) {
                if ((window.components[selectComponent - 1] + amountComponent) <= capacityComponents[selectComponent - 1]) {
                    createComponent(window.tgID, selectComponent, amountComponent)
                        .then((response) => {
                            if (response === 'error') {
                                setAlert('server-error');
                            } else if (response === 'insufficiently') {
                                setAlert('insufficiently');
                            } else if (response === 'Success') {
                                setAmountComponent(1);
                                setUserResources(window.resources.map((value, index) => value - window.resources_market[index]));
                                setUserComponents(window.components.map((value, index) => value - window.components_market[index]));
                                setShowPyro(true);
                                setTimeout(() => { setShowPyro(false) }, 1000);
                                setAlert('component-created');
                            }
                        })
                        .catch(error => {
                            setAlert('server-error');
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    setAlert('component-full');
                    setLoading(false);
                }
            } else {
                setAlert('component-not-money');
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    
    const select = (key) => {
       setSelectComponent(key);
       setAmountComponent(1);
    };

    return (
        <>
            {alert ? <Alert alert={alert} setAlert={setAlert} /> : null}
            {info ? <InfoWorkshop info={info} setInfo={setInfo} /> : null}
            <div className="workshop-header">
                <button className="workshop-back-button" onClick={() => setActiveSection('port')}>
                    <img src={BackImage} alt="Back" />
                </button>
                <div className="workshop-title">
                    <span>{t("workshop")}</span>
                </div>
                <div className="workshop-info">
                    <button onClick={() => setInfo(true)}>i</button>
                </div>
            </div>

            <div className="workshop-page">
                <div className="workshop-table-box">
                    {showPyro && (
                        <div className="pyro">
                            <div className="before"></div>
                            <div className="after"></div>
                        </div>
                    )}
                    <img src={WorkshopImage} alt="Workshop Table" />
                    <div className="workshop-table-overlay">
                        {selectComponent ? (
                            <>
                                <div className="recipe-box">
                                    <div className={`recipe-create-component ${isRecipeValid ? 'valid' : 'invalid'}`}>
                                        <img src={componentImages[selectComponent]} alt={getComponentById(selectComponent).name} />
                                        <div className="recipe-create-amount">{amountComponent}</div>
                                    </div>
                                    <div className="recipe-elements">
                                        {selectComponent ? getComponentById(selectComponent).resources.map((element, index) => (
                                            <div key={index} className={`recipe-element ${calcEnough(element) ? 'valid' : 'invalid'}`}>
                                                {element.resourceId ? (
                                                    <>
                                                    <img src={resourceImages[element.resourceId]} alt={`Resource ${element.resourceId}`} />
                                                    <span>{format(userResources[element.resourceId-1])}/{element.amount*amountComponent}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                    <img src={componentImages[element.componentId]} alt={`Component ${element.componentId}`} />
                                                    <span>{userComponents[element.componentId-1]}/{element.amount*amountComponent}</span>
                                                    </>
                                                )}
                                                
                                            </div>
                                        )) : null}
                                    </div>
                                    {getComponentById(selectComponent).resources.length === 2 ? 
                                        <img className="recipe-lines" src={WorkshopLines2} alt="" /> : getComponentById(selectComponent).resources.length === 3 ?
                                        <img className="recipe-lines" src={WorkshopLines3} alt="" /> : <img className="recipe-lines" src={WorkshopLines4} alt="" />
                                    }
                                </div>
                                <div className="recipe-bottom-box">
                                    <div className="recipe-amount-box">
                                        <button className={`recipe-amount-minus ${loading ? 'disabled' : ''}`} onClick={() => changeAmountComponent('minus')}>
                                            <img src={minus} alt="-" />
                                        </button>
                                        <div className="recipe-amount"> {amountComponent}</div>
                                        <button className={`recipe-amount-plus ${loading ? 'disabled' : ''}`} onClick={() => changeAmountComponent('plus')}>
                                            <img src={plus} alt="+" />
                                        </button>
                                    </div>

                                    <button className={`workshop-create-button ${(!selectComponent || !isRecipeValid || loading) ? 'disabled' : ''}`} onClick={() => createComponentFunc()}>
                                        {loading ? (
                                            <div className="loading-animation-create">
                                                <svg className="spinner" strokeWidth="65px" height="75%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                    <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                                </svg>
                                            </div>
                                        ) : (
                                            <>
                                            <span>{t("create")}</span>
                                            {selectComponent ? (
                                                <>
                                                <span>{formatCapacity(getComponentById(selectComponent).money*amountComponent)}</span>
                                                <img src={resourceImages[1]} alt="Money" />
                                                </>
                                            ) : null}
                                            </>
                                        )}
                                    </button>
                                </div>
                                
                            </>
                        ) : null}
                    </div>
                </div>

                <div className="workshop-components-box">
                    <span className="workshop-components-title">{t("workshop1")}</span>
                    <div className="workshop-components">
                        {Object.entries(componentImages).map(([key, image]) => (
                            <button key={key} className={`workshop-component ${selectComponent === key ? 'selected' : ''}`}  onClick={() => select(key)}>
                                <div className='image'>
                                    <img src={image} alt={`Component ${key}`} />
                                </div>
                                <span className='span'>{userComponents[key-1]}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Workshop;