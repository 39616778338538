import React, { useState, useEffect, useRef } from 'react';
import "./upgrade.scss";
import ReactDOM from 'react-dom';
import { format, formatCapacity } from '../../format-number';
import { useTranslate } from '../../translate/translate';
import { upgradeStore } from '../../requests'
import InfoStore from './info';

const UpgradeStore = ({ upgradeModal, setUpgradeModal, resourceImages, componentImages, config_store, refreshData, setShowPyro, setAlert }) => {
    const t = useTranslate();
    const [infoStore, setInfoStore] = useState(false);
    const [loading, setLoading] = useState(false);

    const currentLvlConfig = config_store.store.find(config => config.lvl === window.lvl_store);
    const nextLvlConfig = config_store.store.find(config => config.lvl === window.lvl_store+1);

    if (!nextLvlConfig) { return null }

    const { resources: currentResources, components: currentComponents } = currentLvlConfig.capacity[0];
    const { resources: nextResources, components: nextComponents } = nextLvlConfig.capacity[0];

    const moneyUpgrade = currentLvlConfig.requirements[0].amount;
    const componentRequirements = currentLvlConfig.requirements.filter(req => req.componentId !== undefined);

    const userResources = window.resources.map((value, index) => value - window.resources_market[index]);
    const userComponents = window.components.map((value, index) => value - window.components_market[index]);

    const upgradeLvl = () => {
        setLoading(true);
        if (currentLvlConfig.lvl != 10 && window.lvl_store != 10) {
            if (userResources[0] >= moneyUpgrade) {
                const moneyCost = Array(14).fill(0);
                moneyCost[0] = moneyUpgrade;
    
                const componentsCount = Array(21).fill(0);
                componentRequirements.forEach(req => {
                    if (req.componentId <= componentsCount.length) {
                        componentsCount[req.componentId - 1] = req.amount;
                    }
                });
    
                const isValid = componentsCount.every((amount, index) => amount <= 0 || amount <= userComponents[index]);
    
                if (isValid) {
                    upgradeStore(window.tgID, moneyCost, componentsCount)
                        .then((response) => {

                            if (response === 'error') {
                                setAlert('server-error');

                            } else if (response === 'True') {
                                refreshData();
                                setShowPyro(true);
                                setTimeout(() => { setShowPyro(false) }, 1000);
                                setAlert('store-upgraded');
                            }
                        })
                        .catch(error => {
                            setAlert('server-error');
                        })
                        .finally(() => {
                            setLoading(false);
                            setUpgradeModal(false);
                        });
                } else {
                    setAlert('not-components-upgrade');
                    setLoading(false);
                }
            } else {
                setAlert('not-money-upgrade');
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    return (
        <>
            {infoStore ? <InfoStore infoStore={infoStore} setInfoStore={setInfoStore} resourceImages={resourceImages}
                componentImages={componentImages} config_store={config_store}/> : null}
            
            {upgradeModal ? (
                ReactDOM.createPortal(
                    <div className="upgrade-store-overlayy" onClick={() => setUpgradeModal(false)}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">
                                <span>{t('store')}</span>
                                <div className="title-lvl">{window.lvl_store} {t('lvl')}</div>
                            </div>
                            <button className="info" onClick={() => setInfoStore(true)}>i</button>
                            <div className="lvl-boxes">
                                <div className="lvl-box">
                                    <div className="capacity-title">
                                        <span className="capacity">{t('capacity')}</span>
                                        <div className="lvl">{window.lvl_store} {t('lvl')}</div>
                                    </div>
                                    <div className="elements">
                                        {currentResources.map((capacity, index) => (
                                            <div key={index} className="element">
                                                <div className="image">
                                                    <img src={resourceImages[index + 1]} alt={`Resource ${index + 1}`} />
                                                </div>
                                                <span className="capacity">{formatCapacity(capacity)}</span>
                                            </div>
                                        ))}
                                        {currentComponents.map((capacity, index) => (
                                            <div key={index} className="element">
                                                <div className="image">
                                                    <img src={componentImages[index + 1]} alt={`Component ${index + 1}`} />
                                                </div>
                                                <span className="capacity">{formatCapacity(capacity)}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="lvl-box">
                                    <div className="capacity-title">
                                        <span className="capacity">{t('capacity')}</span>
                                        <div className="lvl">{window.lvl_store+1} {t('lvl')}</div>
                                    </div>
                                    <div className="elements">
                                        {nextResources.map((capacity, index) => (
                                            <div key={index} className="element">
                                                <div className="image">
                                                    <img src={resourceImages[index + 1]} alt={`Resource ${index + 1}`} />
                                                </div>
                                                <span className="capacity">{formatCapacity(capacity)}</span>
                                            </div>
                                        ))}
                                        {nextComponents.map((capacity, index) => (
                                            <div key={index} className="element">
                                                <div className="image">
                                                    <img src={componentImages[index + 1]} alt={`Component ${index + 1}`} />
                                                </div>
                                                <span className="capacity">{formatCapacity(capacity)}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="upgrade-box">
                                <div className="resources-box">
                                    <div className='element'>
                                        <div className='image'>
                                            <img src={resourceImages[1]} alt='Money' />
                                        </div>
                                        <div className='amount'>{moneyUpgrade}</div>
                                    </div>

                                    {componentRequirements.map((req, index) => (
                                        <div key={index} className='element'>
                                            <div className='image'>
                                                <img src={componentImages[req.componentId]} alt={`Component ${req.componentId}`} />
                                            </div>
                                            <div className='amount'>{req.amount}</div>
                                        </div>       
                                    ))}
                                </div>
                                <button className={`upgrade-button ${(loading) ? 'disabled' : ''}`} onClick={() => upgradeLvl()} >
                                    {loading ? (
                                        <div className="loading-animation">
                                            <svg className="spinner" strokeWidth="65px" height="20%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                            </svg>
                                        </div>
                                    ) : 
                                        <span>{t('upgrade')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default UpgradeStore;