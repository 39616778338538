import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import './rented.scss';
import { useTranslate } from '../../translate/translate';

import { confirmRentedShips } from '../../requests';

const Rented = () => {
    const t = useTranslate();
    const [showModal, setShowModal] = useState(false);
    const [walletLoaded, setWalletLoaded] = useState(false);
    const [rentedShips, setRentedShips] = useState([]);

    useEffect(() => {
        if (window.wallet) {
            setWalletLoaded(true);
        }
    }, [window.wallet]);

    useEffect(() => {
        const fetchData = async () => {
            if (window.wallet) {
                if (window.rented_ships.length > 0) {
                    setRentedShips(window.rented_ships);
                    setShowModal(true);
                }
            }
        };

        if (walletLoaded) {
            fetchData();
        }
    }, [walletLoaded]);

    const confirmNotice = async () => {
        const shipsIds = rentedShips.map(ship => ship.id);
        try {
            await confirmRentedShips(window.tgID, shipsIds);
            setRentedShips([]);
            window.rented_ships = [];
            setShowModal(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            {showModal ? (
                ReactDOM.createPortal(
                    <div className="modal-rented-overlay">
                        <div className="modal-rented">
                            <span className="modal-rented-title">{t("rented1")}</span>
    
                            <div className="modal-rented-info">
                                <span>{t("rented2")}</span>
                                <span>{t("rented3")}</span>
                                <span>{t("rented4")}</span>
                            </div>
                            
                            <div className="modal-rented-ships">
                                {rentedShips.map(ship => (
                                    <div key={ship.id} className="modal-rented-ship">
                                        {ship.name}
                                    </div>
                                ))}
                            </div>
    
                            <button className="modal-rented-ok" onClick={confirmNotice}>{t("ok")}</button>
                        </div>
                    </div>,
                    document.body
                )
            ) : 
            null
            }
        </>
    );
};

export default Rented;