import React, { useState, useEffect, useRef } from 'react';
import "./info.scss";
import ReactDOM from 'react-dom';
import { useTranslate } from '../../translate/translate';

import LeftArrowImage from '../arrow-left.png';
import RightArrowImage from '../arrow-right.png';

const InfoCont = ({ info, setInfo, currentCont, config }) => {
    const t = useTranslate();
    const [lvlPage, setLvlPage] = useState(currentCont.level);
    const [bonus, setBonus] = useState(currentCont.bonus);

    useEffect(() => {
        const containerConfig = config.containers.find(c => c.id === currentCont.name);
        const levelConfig = containerConfig?.levels.find(l => l.level === lvlPage);
        if (levelConfig) {
            setBonus(levelConfig.bonus_res);
        }
    }, [lvlPage, currentCont.name, config]);

    const setLevel = (side) => {
        if (side === 'prev') {

            setLvlPage(prevLvlPage => prevLvlPage - 1);
        } else {
            setLvlPage(prevLvlPage => prevLvlPage + 1);
        }
    }

    return (
        <>       
            {info ? (
                ReactDOM.createPortal(
                    <div className="info-cont-overlay" onClick={() => setInfo(false)}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">{t('containers')}</div>

                            <div className='nav'>
                                <button className='info-lvl-left' onClick={() => setLevel('prev')} disabled={lvlPage === 1}>
                                    {lvlPage != 1 ? <img src={LeftArrowImage} alt="Back" /> : null}
                                </button>

                                <div className='cont-box'>
                                    <img src={currentCont.image} alt={currentCont.name} />
                                    <div className='lvl'>{t('lvl')} {lvlPage}</div>
                                    <span>{t('bonus')}: +{bonus}%</span>
                                </div>

                                <button className='info-lvl-right' onClick={() => setLevel('next')} disabled={lvlPage === 10}>
                                    {lvlPage != 10 ? <img src={RightArrowImage} alt="Next" /> : null}
                                </button>
                            </div>

                            <div className="text">
                                <span className="text2">___________________________________________________</span>
                                <span className="text2"></span>
                                <span className="text2">{t('cont-info1')}</span>
                                <span className="text2">{t('cont-info2')}</span>
                                <span className="text2"><i>{t('cont-info3')}</i></span>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default InfoCont;