import React, { useEffect, useRef, useState } from 'react';
import Loading from './components/loading';

import './components/translate/translate';

import './App.scss';
import Navigation from './components/navigation/navigation';
import Tutorial from './components/tutorial/tutorial';
import ServerErrorModal from './components/server-error';

import techWorksImage from './components/tech_works.png';

//import { processVoyage } from './components/requests.jsx';

function App() {
    const contentRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [tutorial, setTutorial] = useState(false);
    const [step, setStep] = useState(1);
    const [serverError, setServerError] = useState(false);
    const [unlock, setUnlock] = useState(true);
    const [serverTime, setServerTime] = useState(0);

    //////////////////////////////////////////////////////////////////  TELEGRAM /////////////////////////////////////////////////////////////
    window.Telegram.WebApp.expand();
    window.Telegram.WebApp.ready();
    window.Telegram.WebApp.disableVerticalSwipes();

    const viewportHeight = window.Telegram.WebApp.viewportStableHeight;

    document.documentElement.style.height = `${viewportHeight}px`;
    document.body.style.height = `${viewportHeight}px`;

    window.tgID = window.Telegram.WebApp.initDataUnsafe.user.id;
    window.initData = window.Telegram.WebApp.initData;

    if (window.Telegram.WebApp.initDataUnsafe.start_param) {
        window.referer = window.Telegram.WebApp.initDataUnsafe.start_param;
    }

    if (window.Telegram.WebApp.initDataUnsafe.user.first_name) {
        window.firstname = window.Telegram.WebApp.initDataUnsafe.user.first_name;
        if (window.Telegram.WebApp.initDataUnsafe.user.last_name) {
            window.lastname = window.Telegram.WebApp.initDataUnsafe.user.last_name;
        }
    }
    
    /* window.initData = "user=%7B%22id%22%3A7158881931%2C%22first_name%22%3A%22Captain%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22captain_at_sea%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FzPq4eLd2tuiq4wq-5_ZsoyARhiuhSr5Swco6568UftXXMyHy620luurzD21f3inb.svg%22%7D&chat_instance=5156789706976928496&chat_type=sender&auth_date=1733756618&signature=s12HMYVgKUx9fc9X8BuFlQsmNHjk-9I_Mc6448XBB0G3H10LWM612ayjwfsYQX7FFTgUF6d0DbJFXREzA6mhCg&hash=3400ad93b515aa593955f72234fb49b68057f6f4deb0560d6b422fbeb430bd7a"
    window.tgID = 7158881931; //1800928437  7158881931  6158281931
    window.referer = 12;
    window.firstname = 'Account';   
    window.lastname = ''; */
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        const handleResize = () => {
            if (contentRef.current) {
                contentRef.current.style.height = window.innerHeight + 'px';
            }
        };
        handleResize();
    }, []);

    useEffect(() => {
        setServerTime(window.server_time);

        const interval = setInterval(() => {
            setServerTime((prevTime) => prevTime + 1000);
        }, 1000);
    
        return () => clearInterval(interval);
    }, [window.server_time]);

    return (
        <div className='App' ref={contentRef}>

            {/* <button onClick={() => processVoyage()}>Click</button> */}

            {serverError && <ServerErrorModal serverError={serverError}/>}

            {!unlock && (window.tgID !== 7158881931 && window.tgID !== 7016068306 && window.tgID !== 830375200) && (
                <div className='tech-works'>
                    <img src={techWorksImage} alt="Technical Works" />
                    <span className='tech-works-title'>
                        Technical works
                    </span>
                </div>
            )}

            {(unlock || (!unlock && (window.tgID !== 7158881931 || window.tgID !== 7016068306 || window.tgID !== 830375200))) && !serverError && (
                loading ? (
                    <Loading loading={loading} setLoading={setLoading} setTutorial={setTutorial} setServerError={setServerError} setServerTime={setServerTime}/>
                ) : (
                    <>
                    {tutorial ? (<Tutorial tutorial={tutorial} setTutorial={setTutorial} step={step} setStep={setStep} setServerError={setServerError}/>) : null}
                    <Navigation setStep={setStep} setTutorial={setTutorial} serverTime={serverTime}/> 
                    </>
                )
            )}
        </div>
        
    );
}

export default App;
