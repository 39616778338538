import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './alerts.scss';
import { useTranslate } from './translate/translate';

import ErrorIcon from './error.png';
import SuccessIcon from './success.png';
import FuelIcon from './resources/2.png';
import VoyageIcon from './navigation/trip.png';

import Component1Image from './components/1.png';
import Component2Image from './components/2.png';
import Component3Image from './components/3.png';
import Component4Image from './components/4.png';
import Component5Image from './components/5.png';
import Component6Image from './components/6.png';
import Component7Image from './components/7.png';
import Component8Image from './components/8.png';
import Component9Image from './components/9.png';
import Component10Image from './components/10.png';
import Component11Image from './components/11.png';
import Component12Image from './components/12.png';
import Component13Image from './components/13.png';
import Component14Image from './components/14.png';
import Component15Image from './components/15.png';
import Component16Image from './components/16.png';
import Component17Image from './components/17.png';
import Component18Image from './components/18.png';
import Component19Image from './components/19.png';
import Component20Image from './components/20.png';
import Component21Image from './components/21.png';

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const Alert = ({ alert, setAlert }) => {
    const t = useTranslate();
    const [fadeClass, setFadeClass] = useState('fade-in');

    useEffect(() => {
        const timer = setTimeout(() => {
            setFadeClass('fade-out');
            setTimeout(() => {
                setAlert(null);
            }, 300);
        }, 3000);

        return () => clearTimeout(timer);
    }, [alert]);

    return (
        <>
            {alert && ReactDOM.createPortal(
                <div className={`alert-box ${fadeClass}`}>
                    {alert === 'no-fuel' ? (
                        <>
                            <img src={FuelIcon} alt="Error" />
                            <span>{t("alert1")}</span>
                        </> 
                    ) : alert === 'voyage-sended' ? (
                        <>
                            <img src={VoyageIcon} alt="Success" />
                            <span>{t("alert2")}</span>
                        </>
                    ) : alert === 'task-completed' ? (
                        <>
                            <img src={SuccessIcon} alt="Completed" />
                            <span>{t("alert3")}</span>
                        </>   
                    ) : alert === 'task-not-completed' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert4")}</span>
                        </>  
                    ) : alert === 'crew-lvlup' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert5")}</span>
                        </>  
                    ) : alert === 'cont-lvlup' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert6")}</span>
                        </>  
                    ) : alert === 'ship-lvlup' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert7")}</span>
                        </>  
                    ) : alert === 'market-buy' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert8")}</span>
                        </>  
                    ) : alert === 'market-buy-close' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert9")}</span>
                        </>  
                    ) : alert === 'minted-cont' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert10")}</span>
                        </>  
                    ) : alert === 'minted-close' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert11")}</span>
                        </>  
                    ) : alert === 'minted-ship' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert12")}</span>
                        </>  
                    ) : alert === 'component-created' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert13")}</span>
                        </>  
                    ) : alert === 'component-not-money' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert14")}</span>
                        </>  
                    ) : alert === 'store-upgraded' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert15")}</span>
                        </>  
                    ) : alert === 'not-components-upgrade' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert16")}</span>
                        </>  
                    ) : alert === 'not-money-upgrade' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert17")}</span>
                        </>  
                    ) : alert === 'component-full' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert18")}</span>
                        </>  
                    ) : alert === 'buy-error' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert19")}</span>
                        </>  
                    ) : alert === 'market-sell' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert20")}</span>
                        </>  
                    ) : alert === 'market-min-lvl' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert21")}</span>
                        </>  
                    ) : alert === 'rig-capacity-lvlup' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert22")}</span>
                        </>  
                    ) : alert === 'rig-speed-lvlup' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert23")}</span>
                        </>  
                    ) : alert === 'rig-start' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert24")}</span>
                        </>  
                    ) : alert === 'rig-claim' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert25")}</span>
                        </>  
                    ) : alert === 'rig-builded' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert26")}</span>
                        </>  
                    ) : alert === 'error' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert27")}</span>
                        </>  
                    ) : alert === 'rent-close' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert28")}</span>
                        </>  
                    ) : alert === 'rent-success' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert29")}</span>
                        </>  
                    ) : alert === 'task-already' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert30")}</span>
                        </>  
                    ) : alert === 'mg-not-available' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert31")}</span>
                        </>  
                    ) : alert === 'market-buy-error' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert32")}</span>
                        </>  
                    ) : alert === 'coins-add' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert33")}</span>
                        </>  
                    ) : alert === 'no-cancel-lot' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert34")}</span>
                        </>  
                    ) : alert === 'not-cancel-rent' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert35")}</span>
                        </>  
                    ) : alert === 'server-error' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert36")}</span>
                        </>  
                    ) : alert === 'wallet-not-connected' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert37")}</span>
                        </>  
                    ) : alert === 'insufficiently' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert38")}</span>
                        </>  
                    ) : alert === 'lot-canceled' ? (
                        <>
                            <img src={SuccessIcon} alt="Success" />
                            <span>{t("alert39")}</span>
                        </>  
                    ) : alert === 'error-get-minted' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert40")}</span>
                        </>  
                    ) : alert === 'error-get-ships' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert41")}</span>
                        </>  
                    ) : alert === 'ship-error' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert42")}</span>
                        </>  
                    ) : alert === 'conts-error' ? (
                        <>
                            <img src={ErrorIcon} alt="Error" />
                            <span>{t("alert43")}</span>
                        </>  
                    ) : null}   
                </div>,
                document.body
            )}
        </>
    );
};

export default Alert;