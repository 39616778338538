import React, { useState, useEffect } from 'react';
import "./info.scss";
import ReactDOM from 'react-dom';
import { useTranslate } from '../../translate/translate';

import SpeedImage from './speed.png';
import CapacityImage from './capacity.png';
import LeftArrowImage from '../arrow-left.png';
import RightArrowImage from '../arrow-right.png';

const InfoRig = ({ modalInfoRig, setModalInfoRig, rigs, rigs_config }) => {

    const t = useTranslate();
    const [lvlCapacity, setLvlCapacity] = useState(rigs[modalInfoRig].lvl_capacity);
    const [lvlSpeed, setLvlSpeed] = useState(rigs[modalInfoRig].lvl_speed);
    const [capacity, setCapacity] = useState();
    const [speed, setSpeed] = useState();

    useEffect(() => {
        const capacity = rigs_config.rigs[modalInfoRig].capacity.find(level => level.level === lvlCapacity).capacity;
        setCapacity(capacity);
    }, [lvlCapacity]);

    useEffect(() => {
        const speed = rigs_config.rigs[modalInfoRig].speed.find(level => level.level === lvlSpeed).speed;
        setSpeed(speed);
    }, [lvlSpeed]);

    const setLevel = (param, side) => {
        const setLvl = param === 'capacity' ?
            setLvlCapacity : setLvlSpeed;
            setLvl(prevLvl => prevLvl + (side === 'prev' ? -1 : 1));
    }

    return (
        <>      
            {modalInfoRig ? (
                ReactDOM.createPortal(
                    <div className="info-rig-overlay" onClick={() => setModalInfoRig(null)}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">{t('oil-rig')}</div>

                            <div className="nav">
                                <button className='lvl-left' onClick={() => setLevel('capacity', 'prev')} disabled={lvlCapacity === 1}>
                                    {lvlCapacity != 1 ? <img src={LeftArrowImage} alt="Back" /> : null}
                                </button>

                                <div className='param-box'>
                                    <div className='title-param'>
                                        <span>{t('capacity')}</span>
                                        <div className='lvl-box'>
                                            <span>{t('lvl')} {lvlCapacity}</span>
                                        </div>
                                    </div>
                                    <div className='param'>
                                        <div className='img-box'>
                                            <img src={CapacityImage} alt="Capacity" />  
                                        </div>
                                        <span>{capacity}</span>
                                    </div>
                                </div>

                                <button className='lvl-right' onClick={() => setLevel('capacity', 'next')} disabled={lvlCapacity === 10}>
                                    {lvlCapacity != 10 ? <img src={RightArrowImage} alt="Next" /> : null}
                                </button>
                            </div>

                            <div className="nav speed">
                                <button className='lvl-left' onClick={() => setLevel('speed', 'prev')} disabled={lvlSpeed === 1}>
                                    {lvlSpeed != 1 ? <img src={LeftArrowImage} alt="Back" /> : null}
                                </button>

                                <div className='param-box'>
                                    <div className='title-param'>
                                        <span>{t('speed')}</span>
                                        <div className='lvl-box'>
                                            <span>{t('lvl')} {lvlSpeed}</span>
                                        </div>
                                    </div>
                                    <div className='param'>
                                        <div className='img-box'>
                                            <img src={SpeedImage} alt="Speed" />  
                                        </div>
                                        <span>{speed}/{t('h')}</span>
                                    </div>
                                </div>

                                <button className='lvl-right' onClick={() => setLevel('speed', 'next')} disabled={lvlSpeed === 10}>
                                    {lvlSpeed != 10 ? <img src={RightArrowImage} alt="Next" /> : null}
                                </button>
                            </div>

                            <div className="text">
                                <span className="text2">___________________________________________________</span>
                                <span className="text2"></span>
                                <span className="text2">{t('rig-info1')}</span>
                                <span className="text2">{t('rig-info2')}</span>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default InfoRig;