
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './rating.scss';
import Rating2Icon from './rating_2.png';

import { useTranslate } from '../translate/translate';

import Ava1 from './ava/1.png';
import Ava2 from './ava/2.png';
import Ava3 from './ava/3.png';
import Ava4 from './ava/4.png';
import Ava5 from './ava/5.png';
import Ava6 from './ava/6.png';

const avatarImages = {
    1: Ava1,
    2: Ava2,
    3: Ava3,
    4: Ava4,
    5: Ava5,
    6: Ava6,
};

const Rating = ({ isRating, setIsRating }) => {
    const t = useTranslate();

    return (
        <>
        {isRating ? (
            ReactDOM.createPortal(
                <div className='rating-overlay' onClick={() => setIsRating(false)}>
                    <div className='rating-box' onClick={(e) => e.stopPropagation()}>
                        <div className='rating-header'>
                            <span className='rating-title'>{t("rating-title")}</span>
                        </div>
                        {window.rating.user_rank ? (
                            <>
                            <div className='your-rating-box'>
                                <span className='your-rating'>{t("your-rank")}</span>
                                <div className='your-rating-your'>
                                    <span className='your-rating-number'>#{window.rating.user_rank}</span>
                                    <img src={Rating2Icon} alt="Rating" />
                                </div>
                            </div>
                            <div className='all-rating'>
                                {window.rating.top_users.map((user, index) => (
                                    <div className='rating-user' key={user.id}>
                                        <span className={`rating-user-number ${user.id === window.rating.user?.id ? 'current-user' : ''}`}>
                                            #{index + 1}
                                        </span>
                                        <div className='rating-user-img'>
                                            <img src={avatarImages[user.avatar]} alt="" />
                                        </div>
                                        <div className='rating-user-box'>
                                            <div className='rating-user-name'>
                                                <span>{user.firstname}</span>
                                                <span>{user.lastname}</span>
                                            </div>
                                            <div className='rating-user-lvl'>
                                                <span>{t("level")}</span>
                                                <span>{user.lvl}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {window.rating.user && !window.rating.top_users.some(user => user.id === window.rating.user.id) && (
                                    <>
                                    {window.rating.user_rank != 11 && <div className='dots'>···</div>}
                                    <div className='rating-user' key={window.rating.user.id}>
                                        <span className='rating-user-number current-user'>
                                            #{window.rating.user_rank}
                                        </span>
                                        <div className='rating-user-img'>
                                            <img src={avatarImages[window.rating.user.avatar]} alt="" />
                                        </div>
                                        <div className='rating-user-box'>
                                            <div className='rating-user-name'>
                                                <span>{window.rating.user.firstname}</span>
                                                <span>{window.rating.user.lastname}</span>
                                            </div>
                                            <div className='rating-user-lvl'>
                                                <span>{t("level")}</span>
                                                <span>{window.rating.user.lvl}</span>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                )}
                            </div>
                            </>
                        ) : (
                            <div className="rating-animation">
                                <svg className="spinner" strokeWidth="65px" height="40%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                    <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                </svg>
                            </div>
                        )}

                        
                    </div>
                </div>,
                document.body
            ) 
        ) : 
            null
        }
        </>
    );
};

export default Rating;