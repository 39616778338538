
import React, { useEffect, useState } from 'react';
import { useTranslate } from '../translate/translate';

import Map from './map'

import Port from '../port/port';

const MapNav = ({ activeMapSection, setActiveMapSection, setLVL, setXP, serverTime }) => {
    const t = useTranslate();
    const [transitionState, setTransitionState] = useState('fade-in');

    useEffect(() => {
        if (transitionState === 'fade-out') {
            const timer = setTimeout(() => {
                setTransitionState('fade-in');
            }, 150);

            return () => clearTimeout(timer);
        }
    }, [transitionState]);

    const handleChangeMapSection = (section) => {
        setTransitionState('fade-out');
        setTimeout(() => {
            setActiveMapSection(section);
        }, 150);
    };

    const renderMapSection = () => {
        switch (activeMapSection) {
            case 'seaport':
                return <Port handleChangeMapSection={handleChangeMapSection} setLVL={setLVL} setXP={setXP} serverTime={serverTime} />;
            /* case 'autodrome':
                return <Ships setActiveSection={handleChangeSection} serverTime={serverTime} />;
            case 'airport':
                return <Rent setActiveSection={handleChangeSection} serverTime={serverTime} />;
            case 'cosmodrome':
                return <Research setActiveSection={handleChangeSection} />; */
            default:
                return <Map handleChangeMapSection={handleChangeMapSection}/>;
        }
    };

    return (
        <>
            <div className={`map-section ${transitionState}`}>
                {renderMapSection()}
            </div>
        </>
    );
};

export default MapNav;