
import React, { useState, useEffect } from 'react';
import './port.scss';

import Cont from './cont/cont';
import Ships from './ships/ships';
import Rent from './rent/rent';
import Research from './research/research';
import PortContent from './port-content';
import Store from './store/store';
import Workshop from './workshop/workshop';
import Voyage from '../voyage/voyage'

const Port = ({ setActiveMapSection, setLVL, setXP, serverTime }) => {
    const [activeSection, setActiveSection] = useState('port');
    const [transitionState, setTransitionState] = useState('fade-in');

    useEffect(() => {
        if (transitionState === 'fade-out') {
            const timer = setTimeout(() => {
                setTransitionState('fade-in');
            }, 150);

            return () => clearTimeout(timer);
        }
    }, [transitionState]);

    const handleChangeSection = (section) => {
        setTransitionState('fade-out');
        setTimeout(() => {
            setActiveSection(section);
        }, 150);
    };

    const renderSectionPort = () => {
        switch (activeSection) {
            case 'cont':
                return <Cont setActiveSection={handleChangeSection} />;
            case 'ships':
                return <Ships setActiveSection={handleChangeSection} serverTime={serverTime} />;
            case 'rent':
                return <Rent setActiveSection={handleChangeSection} serverTime={serverTime} />;
            case 'research':
                return <Research setActiveSection={handleChangeSection} />;
            case 'store':
                return <Store setActiveSection={handleChangeSection} />;
            case 'workshop':
                return <Workshop setActiveSection={handleChangeSection} />;
            case 'voyages':
                return <Voyage setActiveSection={handleChangeSection} setLVL={setLVL} setXP={setXP} serverTime={serverTime} />;
            default:
                return <PortContent setActiveSection={handleChangeSection} setLVL={setLVL} setXP={setXP} serverTime={serverTime}/>;
        }
    };

    return (
        <div className={`port-section ${transitionState}`}>
            {renderSectionPort()}
        </div>
    );
};

export default Port;