import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './voyages_list.scss';
import { useTranslate } from '../translate/translate';
import BackImage from '../port/back_white.png';

import ContBlueImage from '../port/cont/blue.png';
import ContRedImage from '../port/cont/red.png';
import ContGreenImage from '../port/cont/green.png';
import ContBronzeImage from '../port/cont/bronze.png';
import ContSilverImage from '../port/cont/silver.png';
import ContGoldImage from '../port/cont/gold.png';

const containerImages = {
    'blue-cont': ContBlueImage,
    'red-cont': ContRedImage,
    'green-cont': ContGreenImage,
    'bronze-cont': ContBronzeImage,
    'silver-cont': ContSilverImage,
    'gold-cont': ContGoldImage,
};

const ModalList = ({ modalListVoyages, setModalListVoyages, listSended, setActiveVoyage, setShowModalInfo, serverTime, ships }) => {
    const t = useTranslate();
    const [timers, setTimers] = useState({});
    const [voyages, setVoyages] = useState([]);

    const getInfo = async () => {
        setVoyages(
            listSended.map((voyage) => {
              const shipData = ships.find((ship) => ship.id === voyage.ship);
              return { ...voyage, ship_info: shipData };
            })
        );

        // Инициализация таймеров
        const initialTimers = {};
        listSended.forEach(voyage => {
            const endTime = new Date(voyage.timer).getTime();
            const timeLeft = endTime - serverTime;
            if (timeLeft > 0) {
                initialTimers[voyage.id] = timeLeft;
            }
        });
        setTimers(initialTimers);
    };

    useEffect(() => {
        getInfo();
    }, [listSended]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimers(prevTimers => {
                const newTimers = {};
                Object.keys(prevTimers).forEach(id => {
                    const timeLeft = prevTimers[id] - 1000;
                    if (timeLeft > 0) {
                        newTimers[id] = timeLeft;
                    }
                });
                return newTimers;
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const formatTimeDifference = (timeLeft) => {
        if (timeLeft <= 0) {
            return '00:00:00';
        }

        const hours = Math.floor(timeLeft / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const showInfo = (voyage) => {
        setActiveVoyage(voyage);
        setShowModalInfo(true);
    };

    const renderContainers = (conts) => {
        const containerTypes = ['blue-cont', 'red-cont', 'green-cont', 'bronze-cont', 'silver-cont', 'gold-cont'];
        const nonZeroContainers = conts.filter(count => count > 0);
        const containerCount = nonZeroContainers.length;

        return (
            <div className={`modal-list-voyage-conts cont-count-${containerCount}`}>
                {conts.map((count, index) => {
                    if (count > 0) {
                        const containerType = containerTypes[index];
                        return (
                            <div key={index} className="modal-list-voyage-cont">
                                <img src={containerImages[containerType]} alt={containerType} />
                                <div className="modal-list-voyage-cont-amount">{count}</div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    };

    return (
        <>
            {modalListVoyages && ReactDOM.createPortal(
                <div className="modal-list-overlay">
                    <div className="modal-list-box">
                        <div className="modal-list-header">
                            <button className="back-button" onClick={() => setModalListVoyages(false)}>
                                <img src={BackImage} alt="Back" />
                            </button>
                            <span className="modal-list-title">{t("sended-voyages")}</span>
                        </div>
                        <div className="modal-list-list">
                            {voyages.map((voyage, index) => {
                                const timer = timers[voyage.id] ? formatTimeDifference(timers[voyage.id]) : '00:00:00';
                                return (
                                    <button key={index} className="modal-list-voyage-box" onClick={() => showInfo(voyage)}>
                                        <div className="modal-list-voyage-ship">{voyage.ship_info.name}</div>
                                        {renderContainers(voyage.conts)}
                                        <div className="modal-list-voyage-timer">
                                            {timer}
                                        </div>
                                    </button>
                                ) 
                            })}
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default ModalList;